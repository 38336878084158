import _ from 'lodash';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Player from '@vimeo/player';
import { FaSpinner } from "react-icons/fa";
import ModalShare from './ModalShare';
import { gateway } from '../../../utils/api';
import ViewEditor from '../../shared/ViewEditor';
import * as Styles from './styles';
import { Creators as VideoActions } from '../../../store/ducks/newCourse/actions';

const VideoV2 = ({
  videoUrl,
  description,
  selected,  
  setPlayerPosition,
  setCourseSelected,
  setDisciplineProgress,
  navigationContent,
  incrementCourseProgress,
  shareMenu,
  showDuvidas,
  shareScreen,
  videoWidth = 800,
  videoHeight = 450,
  fromMobile = false
}) => {
  const playerContainerRef = useRef(null);
  const playerRef = useRef(null);
  const idProfile = useRef(parseInt(localStorage.getItem('idProfile'), 10));
  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [loading, setLoading] = useState(true);
  const [videoPlayerUrl, setVideoPlayerUrl] = useState(null);
  const countdownRef = useRef(null);

  const getPlayedTime = useCallback(async () => {
    if (playerRef.current) {
      const time = await playerRef.current.getCurrentTime();
      return time || 0;
    }
    return 0;
  }, []);

  const fetchVideoFromBackend = useCallback(async (videoId) => {
    try {
      const response = await gateway.get(`/gatewayout/vimeo/fetch-vimeo-video?idVideo=${videoId}`);
      setVideoPlayerUrl(response.url);  
    } catch (error) {
      console.error('Erro ao buscar vídeo:', error);
    }
  }, []);

  function extractVimeoId(url) {
    const regex = /vimeo\.com\/(?:video\/)?(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }


  useEffect(() => {
    if (selected && selected.vimeo_url) {
      const videoId = extractVimeoId(selected.vimeo_url);
      if (!videoPlayerUrl || videoPlayerUrl !== selected.vimeo_url) {
        fetchVideoFromBackend(videoId);
      }
    } else {
      console.error('Erro ao carregar o vídeo ou URL inválida');
    }
  }, [selected, fetchVideoFromBackend, videoPlayerUrl]);
  
  

  const sendObjectProgress = useCallback(async (selected, action, status, playbackRate) => {
    try {
      const sessionCourse = localStorage.getItem('session');
      const time = await getPlayedTime();
      await gateway.post('/webbff/lit_app_web/course_progress/course_progress_object/', {
        selected: { code: selected.code, courseCode: selected.courseCode },
        idProfile: idProfile.current,
        courseSession: sessionCourse,
        action,
        status,
        type: 'VIDEO',
        mode: playbackRate,
        position: Math.floor(time) || 0,
      });
    } catch (error) {
      console.error('Erro ao enviar progresso do objeto:', error);
    }
  }, [getPlayedTime]);

  const startNextVod = () => {
    setCourseSelected(navigationContent.next);
    setShowModal(false);
    setCountdown(5);
  };

  const cancelNextVod = () => {
    setShowModal(false);
    clearTimeout(countdownRef.current);
    setCountdown(5);
  };

  const handleCountdown = () => {
    countdownRef.current = setTimeout(startNextVod, 5000);
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const sendMobileEvent = (status) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(status);
    } else {
      console.log('ReactNativeWebView não está disponível.');
    }
  }

  const finishAttachment = async (action) => {
    setShowModal(true);
    setCountdown(5);
    handleCountdown();

    const progressFinished = {
      idProfile: idProfile.current,
      hierarchy: selected.hierarchy,
      contentCode: selected.code,
      courseCode: selected.courseCode,
      position: 0,
      status: 'DONE'
    };

    const objectStatusProgress = _.get(selected, 'contentProgress.ox_status.wk_status', 'IN_PROGRESS');
    await sendObjectProgress(selected, action, 'DONE', 1.0);

    const finishObject = await gateway.post('/webbff/lit_app_web/progress/saveProgress', progressFinished);
    if (objectStatusProgress !== 'DONE') {
      const progress = _.get(finishObject, 'progress', []);
      const foundObject = progress.find(p => p.rk_reference === selected.code);
      const status = _.get(foundObject, 'wk_status');
      incrementCourseProgress(status);
    }

    setDisciplineProgress(selected.idDiscipline);

    if (window.ReactNativeWebView) {
      sendMobileEvent('VIDEO_FINISHED'); // message for mobile app
    } else {
      document.getElementById(`course-object-${selected.code}`).classList.add('active');
    }
  };

  const saveProgress = useCallback(async (action) => {
    try {
      const time = await getPlayedTime();
      if (time > 0) {
        const progress = {
          idProfile: idProfile.current,
          hierarchy: selected.hierarchy,
          contentCode: selected.code,
          courseCode: selected.courseCode,
          position: Math.floor(time),
          status: 'IN PROGRESS',
        };
        setPlayerPosition(progress.position);
        await Promise.all([
          gateway.post('/webbff/lit_app_web/progress/saveProgress', progress),
          sendObjectProgress(selected, action, 'IN PROGRESS', await playerRef.current.getPlaybackRate())
        ]);
      }
    } catch (error) {
      console.error('Erro ao salvar progresso:', error);
    }
  }, [getPlayedTime, selected, sendObjectProgress, setPlayerPosition]);

  useEffect(() => {
    if (videoPlayerUrl) {
      if (playerRef.current) {
        // const currentUrl = playerRef.current.element.getAttribute('src');
        // if (currentUrl === videoPlayerUrl) return; 
        playerRef.current.destroy();
        playerRef.current = null;
      }

      const options = {
        url: videoPlayerUrl,
        autoplay: true,
        responsive: !fromMobile,
        width: fromMobile ? window.innerWidth : videoWidth,
        height: fromMobile ? window.innerHeight : videoHeight,
        loop: false,
        byline: false,
        portrait: false,
      };
      const player = new Player(playerContainerRef.current, options);

      playerRef.current = player;
      player.on('loaded', () => {
        setLoading(false);  
      });
      if (selected.contentProgress.ox_status.nx_value) {
        player.setCurrentTime(selected.contentProgress.ox_status.nx_value).catch(error => {
          console.error('Erro ao ajustar o tempo inicial do vídeo:', error);
        });
      }
  
      const debouncedSaveProgress = _.debounce(async (action) => {
        await saveProgress(action);  
      }, 1000);  
  
      const eventListeners = {
        play: () => saveProgress('STARTED'),
        pause: () => saveProgress('PAUSED'),
        seeked: async () => {
          const currentTime = await getPlayedTime();
          setPlayerPosition(currentTime);
          debouncedSaveProgress('ADVANCED');
        },
        ended: async () => {
          await player.setCurrentTime(0);
          await finishAttachment('DONE');
        }
      }
    Object.keys(eventListeners).forEach(event => player.on(event, eventListeners[event]));
    return () => {
      Object.keys(eventListeners).forEach(event => player.off(event, eventListeners[event]));
      player.destroy(); 
      playerRef.current = null;
      setShowModal(false);
      clearTimeout(countdownRef.current);
      setCountdown(5); // Reinicia o countdown
    };

    };

  }, [videoPlayerUrl, selected, getPlayedTime, saveProgress]);
  // useEffect(() => {
  //   if (playerRef.current) {
  //     setLoading(true); 
  //     playerRef.current.on('loaded', () => setLoading(false));
  //   }
  // }, [videoPlayerUrl]);
  

  return (
    <div style={{ position: 'relative' }}>
      <div ref={playerContainerRef} style={{ width: '100%', height: 'auto' }}>
        {loading && !fromMobile && (<Styles.LoadingWrapper>
          <FaSpinner size={50} color="#38ade3" />

        </Styles.LoadingWrapper>)}
      </div>

      {showModal && !window.ReactNativeWebView && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999,
        }}>
          <div style={{ backgroundColor: '#fff', padding: '2.5em', borderRadius: '8px' }} className="w-50">
            <div className="row">
              <div className="col-12">
                <span>
                  <strong>O próximo vídeo inicia em {countdown} segundos</strong>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button onClick={startNextVod} className="btn btn-blue btn-block" type="button">
                  Próximo
                </button>
              </div>
              <div className="col-6">
                <button onClick={cancelNextVod} className="btn btn-cancel btn-block" type="button">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {description && (
        <Styles.ObjectDescription>
          <ViewEditor data={description} />
        </Styles.ObjectDescription>
      )}

      {selected.knowMore.length > 0 && <h4>SAIBA MAIS - Arquivos</h4>}
      {selected.knowMore.length > 0 && selected.knowMore.map((item, index) => {
        const { media } = item;
        return (
          <Styles.ItemContainer key={index}>
            <Styles.ContainerIcon>{_.get(media, 'type', '?')}</Styles.ContainerIcon>
            <Styles.ItemDescription>
              {_.get(media, 'name', '?')}
            </Styles.ItemDescription>
            {_.get(media, 'path', false) && (
              <Styles.BtnDownload
                type="button"
                className="btn btn-light"
                onClick={() => this.downloadAnexo({ path: media.path })}
              >
                <i className="fa fa-download" aria-hidden="true" />
              </Styles.BtnDownload>
            )}
          </Styles.ItemContainer>
        );
      })}

      <Modal
        open={shareScreen}
        onClose={shareMenu}
        classNames={{ modal: 'lit-perfil-form' }}
        closeOnOverlayClick={false}
      >
        <ModalShare close={shareMenu} showDuvidas={showDuvidas} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  playerPosition: state.newCourse.playerPosition,
  navigationContent: state.newCourse.navigationContent,
  autoplayNextVideos: state.newCourse.autoplayNextVideos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(VideoActions, dispatch);


export default React.memo(connect(mapStateToProps, mapDispatchToProps)(VideoV2));
