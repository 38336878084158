/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import mathlive from 'mathlive';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import _ from 'lodash';
import MultipleChoice from './subcomponents/MultipleChoice';
import SingleChoice from './subcomponents/SingleChoice';
import Descriptive from './subcomponents/Descriptive';
import TrueOrFalse from './subcomponents/TrueOrFalse';
import Upload from './subcomponents/Upload';

import SurveyPdfComponent from './subcomponents/SurveyPdfComponent';
import SurveyImageComponent from './subcomponents/SurveyImageComponent';
import SurveyVideoComponent from './subcomponents/SurveyVideoComponent';
import AnswerResult from './subcomponents/AnswerResult';
import ViewEditor from '../../../shared/ViewEditor';

import {
  Container,
  Title,
  EnumCard,
  ContainerAlternative,
  Alternative,
  ButtonContainer,
  Button,
  Status,
  ContainerAnswerResult,
  ContainerHint,
  HintViewEditor
} from './styles';

const mappedComponents = {
  MULTIPLE_CHOICE: MultipleChoice,
  SINGLE_CHOICE: SingleChoice,
  DESCRIPTIVE: Descriptive,
  TRUE_OR_FALSE: TrueOrFalse,
  PROGRESSIVE: SingleChoice,
  UPLOAD: Upload
};

const mappedMidiaTypeComponents = {
  VIDEO: SurveyVideoComponent,
  PDF: SurveyPdfComponent,
  IMAGE: SurveyImageComponent
};

function SurveyView({
  surveyData,
  surveyAnswers,
  onAnswerChange,
  getFeedbacks,
  numberQuestion,
  loading
}) {
  const renderText = useCallback(text => {
    // Pattern to identify latex expression like {\infty}
    const latexPattern = /\\\w+({.*})?/g;
    const hasLatex = latexPattern.test(text);
    if (hasLatex) {
      return (
        <span
          style={{ marginLeft: 5 }}
          dangerouslySetInnerHTML={{
            __html: mathlive.latexToMarkup(text)
          }}
        />
      );
    }

    const formatedData = `<p>${text || 'Sem descrição'}</p>`;
    return <ViewEditor data={formatedData} />;
  }, []);

  const renderMedia = useCallback(({ id, type, path, name }) => {
    const MediaComponent =
      Object.keys(mappedMidiaTypeComponents).includes(type) &&
      mappedMidiaTypeComponents[type];

    return <MediaComponent id={id} path={path} name={name} />;
  }, []);

  const renderStatus = useCallback(() => {
    const surveyStatus = _.get(surveyData, 'surveyStatus', null);

    const statusType = {
      INCORRECT: 'INCORRETA',
      CORRECT: 'CORRETA',
      PARTIALLY_CORRECT: 'PARCIALMENTE CORRETA'
    };

    if (surveyStatus) {
      switch (surveyStatus.toLowerCase()) {
        case 'incorrect':
          return (
            <Status status={surveyStatus}>
              <FaTimesCircle />
              <span>{statusType[surveyStatus]}</span>
            </Status>
          );
        default:
          return (
            <Status status={surveyStatus}>
              <FaCheckCircle />
              <span>{statusType[surveyStatus]}</span>
            </Status>
          );
      }
    }

    return '';
  }, [surveyData]);

  const renderBorderColor = useCallback(answerResult => {
    if (answerResult && answerResult.status) {
      return answerResult.status;
    }
    return '';
  }, []);

  const renderAnswerResult = useCallback((answerResult, type) => {
    if (answerResult) {
      return <AnswerResult data={answerResult} type={type} />;
    }
    return null;
  }, []);

  const renderHint = useCallback(answerResult => {
    if (answerResult && answerResult.hint) {
      return (
        <ContainerHint>
          <p id="hint">DICA</p>
          <HintViewEditor data={answerResult.hint} />
        </ContainerHint>
      );
    }
    return null;
  });

  const AnswerComponent =
    surveyData &&
    Object.keys(mappedComponents).includes(surveyData.type) &&
    mappedComponents[surveyData.type];

  return (
    surveyData &&
    surveyAnswers && (
      <Container>
        <Title>
          <span>
            Questão{' '}
            {numberQuestion < 10 ? `0${numberQuestion}` : numberQuestion}
          </span>
          {renderStatus()}
        </Title>

        <EnumCard>
          {renderText(surveyData.statement)}
          {surveyData.media && renderMedia(surveyData.media)}
        </EnumCard>

        {surveyData.alternatives &&
          surveyData.alternatives.map((alternative, index) => {
            const isUserAnswerTrue = _.get(alternative, 'userAnswer', false);

            return (
              <ContainerAnswerResult key={alternative.id}>
                {renderAnswerResult(
                  _.get(alternative, 'answerResult', null),
                  surveyData.type
                )}
                <ContainerAlternative
                  borderColor={renderBorderColor(
                    _.get(alternative, 'answerResult', null)
                  )}
                  noBorder={
                    surveyData.type === 'DESCRIPTIVE' ||
                    surveyData.type === 'UPLOAD'
                  }
                >
                  <Alternative>
                    {AnswerComponent && (
                      <AnswerComponent
                        id={alternative.id}
                        index={index}
                        renderLabel={() => renderText(alternative.statement)}
                        value={_.get(alternative, 'userAnswer', null)}
                        onAnswerChange={onAnswerChange}
                      />
                    )}
                  </Alternative>
                  {alternative.media && renderMedia(alternative.media)}
                </ContainerAlternative>

                {isUserAnswerTrue && renderHint(alternative.answerResult)}
              </ContainerAnswerResult>
            );
          })}

        {surveyData.feedback && (
          <ButtonContainer>
            <Button
              color="#00ACE4"
              onClick={() => {
                getFeedbacks(surveyData.feedback);
              }}
              className={`btn ${loading && 'btn-loading'}`}
              width={200}
              height={50}
            >
              VER FEEDBACK
            </Button>
          </ButtonContainer>
        )}
      </Container>
    )
  );
}

export default SurveyView;
