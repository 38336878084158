import React from 'react';
import _ from 'lodash';

const Footer = () => {
  const settings = JSON.parse(localStorage.getItem('settings')) || null;
  const tenant = localStorage.getItem('tenant') || '';

  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
    settings,
    `${tenant}`,
    {}
  );

  return (
    <footer
      style={{
        background: headerBackgroundColor || headerBackgroundGradient,
        textAlign: 'center',
        padding: '1rem 0',
        color: '#fff',
        fontSize: '14px',
      }}
    >
      <span>Powered by Exame | Saint Paul</span>
    </footer>
  );
};

export default Footer;
