import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import 'moment/locale/pt-br';

import NavBar from '../Termos/Navbar';
import RequiredSpan from '../RequiredSpan';
import { gateway } from '../../utils/api';

const GIF_LIT = require('../../images/animacao_paul_standby.gif');

class Avaliacao extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  state = {
    considerations: '',
    evaluate: null,
    loading: false,
    errors: ''
  };

  get validationForm() {
    const { evaluate } = this.state;
    return !evaluate;
  }

  saveForm = async () => {
    try {
      this.setState({ loading: true, errors: '' });

      const idProfile = localStorage.getItem('idProfile');
      const { considerations, evaluate } = this.state;

      await gateway.put(
        `/webbff/lit_app_web/nps/submitLoginAssessment/${idProfile}`,
        {
          considerations,
          evaluate
        }
      );

      this.setState({ loading: false });
      this.nextStep();
    } catch (error) {
      this.setState({
        errors: 'Não foi possível enviar os dados, tente novamente mais tarde',
        loading: false
      });
    }
  };

  makeLaterNps = async () => {
    const idProfile = localStorage.getItem('idProfile');
    await this.makeLaterNps(idProfile);
    this.nextStep();
  };

  nextStep = async () => {
    const { router } = this.context;
    router.history.push('/home');
  };

  makeLaterNps = async () => {
    const idProfile = localStorage.getItem('idProfile');
    await gateway.put(`/webbff/lit_app_web/nps/makeLaterNps/${idProfile}`);
    this.nextStep();
  };

  changeEvaluate = event => {
    this.setState({ evaluate: parseInt(event.target.value, 10) });
  };

  render() {
    const { considerations, evaluate, loading, errors } = this.state;
    return (
      <div id="interesses">
        <NavBar />

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div
                            className="col-md-6 text-center"
                            id="objetivos-interesses--desc-usuario"
                          >
                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                          </div>

                          <div
                            className="col-md-6 text-center"
                            id="objetivos-interesses--desc-usuario"
                          >
                            <h5
                              style={{ color: '#27aae1' }}
                              className="text-center"
                            >
                              Sua Avaliação
                            </h5>
                            <form onSubmit={this.createExperience}>
                              <div className="modalBody-container">
                                <fieldset>
                                  <p className="col-form-label pt-0">
                                    Avalie de 0 a 10 o quanto você recomendaria
                                    o LIT para outras pessoas: <RequiredSpan />
                                  </p>
                                  <div className="form-group mb-0">
                                    <ul className="pt-0">
                                      {Array(11)
                                        .fill()
                                        .map((item, index) => {
                                          return (
                                            <li
                                              style={{
                                                display: 'inline-block',
                                                marginRight: 10
                                              }}
                                              key={index}
                                            >
                                              <label>
                                                <input
                                                  className="form-check-input"
                                                  style={{ margin: 0 }}
                                                  type="radio"
                                                  value={index}
                                                  checked={evaluate === index}
                                                  onChange={this.changeEvaluate}
                                                />{' '}
                                                {index}
                                              </label>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                </fieldset>
                                <div className="form-group">
                                  <label>Considerações (opcional)</label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Deixe sua consideração"
                                    rows="4"
                                    maxLength="200"
                                    value={considerations}
                                    onChange={evt =>
                                      this.setState({
                                        considerations: evt.target.value
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div
                          className="row justify-content-center"
                          id="selecionar-personalizar"
                        >
                          <div
                            style={{ marginBottom: '5px' }}
                            className="d-flex justify-content-center col-md-2"
                          >
                            <button
                              type="button"
                              onClick={this.makeLaterNps}
                              className="btn btn-blue btn-FontWeight6"
                            >
                              Fazer Depois
                            </button>
                          </div>

                          <div>
                            <button
                              type="button"
                              disabled={this.validationForm || loading}
                              onClick={this.saveForm}
                              className={`btn btn-blue btn-FontWeight6 ${loading &&
                                ' btn-loading'}`}
                            >
                              Enviar Avaliação
                            </button>
                          </div>
                        </div>
                        <div>
                          <p style={{ color: 'red', textAlign: 'center' }}>
                            {errors}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Avaliacao;
