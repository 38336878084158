import React, { useEffect, useState } from "react";

function DarkMode({ pathRoute }) {
    const [darkModeStatus, setDarkModeStatus] = useState(() =>
        localStorage.getItem("darkmode") || "off"
    );

    const activeDarkMode = () => {
        if (darkModeStatus === "on") {
            setDarkModeStatus("off");
            localStorage.setItem("darkmode", "off");
            document.body.classList.remove("dark-mode");
        } else {
            setDarkModeStatus("on");
            localStorage.setItem("darkmode", "on");
            document.body.classList.add("dark-mode");
        }
    };

    useEffect(() => {
        if (pathRoute === "/home") {
            const savedDarkModeStatus = localStorage.getItem("darkmode");
            if (savedDarkModeStatus === "on") {
                setDarkModeStatus("on");
                document.body.classList.add("dark-mode");
            } else {
                setDarkModeStatus("off");
                document.body.classList.remove("dark-mode");
            }
        } else {
            setDarkModeStatus("off");
            document.body.classList.remove("dark-mode");
        }
    }, [pathRoute]);

    return (
        <a
            style={{ marginTop: 2, cursor: "pointer" }}
            className="nav-link"
            onClick={() => pathRoute === "/home" && activeDarkMode()} 
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && pathRoute === "/home" && activeDarkMode()} 
        >
            <i className="fa fa-moon-o" aria-hidden="true" />{" "}
            {darkModeStatus === "on" && pathRoute === "/home" ? "Modo noturno" : ""}
        </a>
    );
}

export default DarkMode;
