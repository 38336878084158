
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { DownArrowAlt } from "styled-icons/boxicons-regular"
import { Select } from 'antd';
import CreditCardForm from '../CreditCardForm';
import InvoiceForm from '../InvoiceForm';
import PayrollForm from '../PayrollForm';
import PixForm from '../PixForm';
import CampaignOffer from '../CampaignOffer';
import FullPercentDiscount from './subcomponents/FullPercentDiscount';
import { formatPrice } from '../../../../utils/formatPrice';

import history from '../../../../utils/history';
import logo from '../../../../images/sp_lit.png';

import logoPix from "../../../../images/logo-pix.png"
import logoCredit from "../../../../images/cartao-de-credito.png"
import logoBoleto from "../../../../images/boleto-logo.svg"
import logoPayroll from "../../../../images/payroll.png"

import * as Styles from './styles';
import { gateway } from '../../../../utils/api';
import {
  renewToken,
  toastErrorAndWarningOptions,
  toastDefaultOptions
} from '../../../../utils/functions';

import handleRequestErrorMessage from '../../helpers/handleRequestErrorMessage';
import Loading from '../../../Loading/Loading';
import { Creators as InstallmentsActions } from '../../../../store/ducks/payments/installments/actions';
import { Creators as CouponActions } from '../../../../store/ducks/payments/coupon/actions';
import { Creators as PlanActions } from '../../../../store/ducks/payments/plan/actions';
import getTotalPriceByInstallmentsDiscount from '../../helpers/getTotalPriceByInstallmentsDiscount';


const PAYMENT_METHODS = {
  INVOICE: 'INVOICE',
  CREDIT: 'CREDIT',
  PAYROLL: 'PAYROLL',
  PIX: 'PIX'
};

const PAYMENT_METHODS_ICONS = {
  INVOICE: <img src={logoBoleto} alt="boleto" />,
  CREDIT: <img src={logoCredit} alt="credit" />,
  PAYROLL: <img src={logoPayroll} alt="payroll" />,
  PIX: <img src={logoPix} alt="pix" />
};

const PAYMENT_METHODS_NAMES = {
  INVOICE: 'Boleto',
  CREDIT: 'Cartão de Crédito',
  PAYROLL: 'Desconto em Folha',
  PIX: 'PIX'
};

const PAYMENT_METHODS_ORDER = {
  CREDIT: 1,
  INVOICE: 2,
  PAYROLL: 3,
  PIX: 4
};

function PaymentForm({
  selectedPlan,
  setSelectedPlan,
  recommendation,
  showHeader,
  closeModal
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [hideTotalPriceLabel, setHideTotalPriceLabel] = useState(false)
  const [loading, setLoading] = useState(false);
  const [finishedPayment, setFinishedPayment] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [defaultPlan, setDefaultPlan] = useState(null);
  const [, setCampaignsOption] = useState([]);
  const [showCampaign, setShowCampaign] = useState(false);
  const installments = useSelector(installments => installments.installments);
  const [couponCode, setCoupon] = useState("");
  const [couponData, setCouponData] = useState();
  const [priceWithDiscount, setPriceWithDiscount] = useState();
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [showCupomForm, setShowCupomForm] = useState(false)
  const [isDispetchPayment, setIsDispetchPayment] = useState(false)
  const dispatch = useDispatch();

  const [autoApplyCoupon, setAutoApplyCoupon] = useState(false);
  const prevCouponCode = useRef();

  const verifyCouponIsFull = coupon => {
    return !!(
      coupon &&
      coupon.type &&
      coupon.value &&
      coupon.type === 'PERCENT' &&
      coupon.value === 100
    );
  };

  const onSelectedPaymentMethod = useCallback(
    value => {
      const initialInstallment = 0;
      const installmentData = getTotalPriceByInstallmentsDiscount(
        initialInstallment,
        selectedPlan,
        value,
        couponData
      );

      dispatch(InstallmentsActions.setInstallmentValue(installmentData));
      setSelectedPaymentMethod(value);
      setHideTotalPriceLabel(installmentData.hideTotalPriceLabel)
    },
    [
      dispatch,
      couponData,
      getTotalPriceByInstallmentsDiscount,
      selectedPlan,
      setSelectedPaymentMethod
    ]
  );

  const performTokenRenew = useCallback(async () => {
    try {
      setLoading(true);
      await renewToken();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  });

  const handlePaymentSuccess = useCallback(
    async ({
      paymentResult,
      paymentMethod,
      gatewayReference,
      selectedPlan: paidPlan,
      paidValue,
      contractCode,
      alreadyPurchased = false
    }) => {

      setFinishedPayment(true);

      if (alreadyPurchased && closeModal) {
        toast('Dados alterados com sucesso!', toastDefaultOptions);
        closeModal();
        return;
      }

      await performTokenRenew();
      localStorage.setItem('voucherAtivo', true);
      localStorage.setItem('activeContract', true);
      localStorage.setItem('freeUser', _.get(paymentResult, 'freeUser', false));
      localStorage.setItem(
        'libraryUser',
        _.get(paymentResult, 'library', false)
      );
      localStorage.setItem('socialUser', _.get(paymentResult, 'social', false));
      localStorage.setItem('showPaul', _.get(paymentResult, 'paul', false));
      localStorage.removeItem('paymentInfo');

      localStorage.removeItem('sellerCode');
      localStorage.removeItem('couponCode');
      localStorage.removeItem('sellerLinkId');

      if (paymentMethod === PAYMENT_METHODS.INVOICE && paymentResult) {
        history.push('/invoicePaymentView', {
          nextPageParams: {
            fromPayment: true,
            userHasAccess: true,
            invoiceData: paymentResult,
            paidPlan,
            paymentResult,
            gatewayReference,
            paidValue,
            contractCode
          }
        });
        return
      }

      if (paymentMethod === PAYMENT_METHODS.PIX && paymentResult) {
        // TODO: Probably should be removed
        let redirectPath = '';
        if (paymentResult.redirectMyCourse) {
          redirectPath = '/cursos';
        } else {
          redirectPath = '/sucesso_corporativo';
        }
        history.push(redirectPath, {
          paymentMethod,
          paidPlan,
          paymentResult,
          gatewayReference,
          paidValue,
          contractCode
        });
        return
      }

      const tenant = localStorage.getItem('tenant');
      let redirectPath = '/sucesso_corporativo';

      const response = await gateway.get("/onboarding/steps");

      if (tenant && tenant === 'INTERPLAYERS' || tenant === 'ARTESANAL_INVESTIMENTOS' || response.some(item => item.bf_concluded === true)) {
        redirectPath = '/sucesso_corporativo?onboardIsCompleted=true';
      }

      history.push(redirectPath, {
        paymentMethod,
        paidPlan,
        paidValue,
        paymentResult: {
          ...paymentResult,
          ...paidPlan,
        },
        gatewayReference,
        contractCode
      });
    },
    []
  );

  const renderForm = useMemo(() => {
    switch (selectedPaymentMethod) {
      case PAYMENT_METHODS.INVOICE:
        return (
          <InvoiceForm
            selectedPlan={selectedPlan}
            onPaymentSuccess={handlePaymentSuccess}
            couponCode={couponCode}
          />
        );
      case PAYMENT_METHODS.CREDIT:
        return (
          <CreditCardForm
            selectedPlan={selectedPlan}
            onPaymentSuccess={handlePaymentSuccess}
            couponCode={couponCode}
          />
        );
      case PAYMENT_METHODS.PAYROLL:
        return (
          <PayrollForm
            selectedPlan={selectedPlan}
            onPaymentSuccess={handlePaymentSuccess}
          />
        );
      case PAYMENT_METHODS.PIX:
        return (
          <PixForm
            selectedPlan={selectedPlan}
            onPaymentSuccess={handlePaymentSuccess}
            couponCode={couponCode}
          />
        );
      default:
        return null;
    }
  }, [selectedPaymentMethod, selectedPlan, couponCode]);

  const paymentMethods = useMemo(() => {
    const planPaymentMethods = _.get(selectedPlan, 'paymentMethods', []);

    const firstPaymentMethod = _.first(planPaymentMethods);

    const pixDataStr = localStorage.getItem('pixData');

    if (pixDataStr) {
      setSelectedPaymentMethod(PAYMENT_METHODS.PIX);
    } else if (!recommendation && firstPaymentMethod) {
      setSelectedPaymentMethod(firstPaymentMethod.type);
    }

    return planPaymentMethods
      .map(planPaymentMethod => ({
        type: planPaymentMethod.type,
        name: PAYMENT_METHODS_NAMES[planPaymentMethod.type],
        order: PAYMENT_METHODS_ORDER[planPaymentMethod.type]
      }))
      .sort((a, b) => a.order - b.order);
  }, [selectedPlan]);

  async function handleSubmitPayment() {
    try {
      setLoading(true);

      const url = '/contract/contract/processSubscription';

      const paymentRequestBody = {
        type: 'BILLED',
        offerReference: selectedPlan.code,
        crCodeCoupon: couponCode
      };

      const paymentResponse = await gateway.post(url, paymentRequestBody);

      handlePaymentSuccess({
        selectedPlan,
        paymentResult: paymentResponse.paymentInfo,
        paymentMethod: 'BILLED'
      });
    } catch (error) {
      handleRequestErrorMessage(error);
    }
  }

  function setPlanCampaign(code) {
    try {
      if (!code) {
        return;
      }
      setLoading(true);

      const findedCampaign = campaigns.find(campaign => campaign.code === code);

      setSelectedPlan(findedCampaign);

      if (selectedPlan.type === 'OFFER') {
        setDefaultPlan(selectedPlan);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleApplyCoupon = async () => {
    console.log(" handleApplyCoupon couponCode ------>", couponCode)
    console.log(" handleApplyCoupon selectedPlan ------>", selectedPlan)
    if (couponCode) {
      try {
        setIsLoadingCoupon(true);
        const offerCrCode = selectedPlan.code;
        const url = `/coupon/coupon/verifyCouponIsValid/${couponCode}/${offerCrCode}`;

        const couponResponse = await gateway.get(url);

        if (couponResponse.isValid && couponResponse.couponData) {
          setCouponData(couponResponse.couponData);

          dispatch(CouponActions.setCouponData(couponResponse.couponData));

          const initialInstallment = 0;
          const installmentData = getTotalPriceByInstallmentsDiscount(
            initialInstallment,
            selectedPlan,
            selectedPaymentMethod,
            couponResponse.couponData
          );

          dispatch(InstallmentsActions.setInstallmentValue(installmentData));
          dispatch(PlanActions.updateInstallmentSelectedPlan({
            selectedPaymentMethod,
            couponData: couponResponse.couponData,
            installmentData
          }))
          toast.success('Cupom de desconto aplicado com sucesso.');
          setSelectedPaymentMethod(selectedPaymentMethod)
          setIsLoadingCoupon(false);
        } else {
          dispatch(CouponActions.setCouponData(couponResponse.couponData));
          setIsLoadingCoupon(false);
          toast.warn(
            'Não foi possível aplicar o cupom de desconto.',
            toastErrorAndWarningOptions
          );
        }
      } catch (err) {
        console.log("----------->", err);
        setIsLoadingCoupon(false);
        handleRequestErrorMessage(err);
      }
    } else {
      dispatch(CouponActions.setCouponData({ type: '', value: undefined }));
      setCouponData(undefined);
      setCoupon(undefined);
    }
  };

  function logout() {
    history.push('/logout');
  }

  useEffect(() => {
    if (couponCode !== prevCouponCode.current && autoApplyCoupon) {
      handleApplyCoupon();
      setAutoApplyCoupon(false); // Reset para false após a aplicação
    }
    prevCouponCode.current = couponCode;
  }, [couponCode, autoApplyCoupon]);

  useEffect(() => {
    const couponCode = localStorage.getItem('couponCode');
    if (selectedPlan && couponCode) {
      setShowCupomForm(true);
      setCoupon(couponCode);
      setAutoApplyCoupon(true)
    }

    const campaignOffer = _.get(selectedPlan, 'campaigns', []);
    setCampaigns(campaignOffer);

    const newFormattedCampaigns = [
      { label: 'Escolha uma campanha', value: '' }
    ].concat(
      campaignOffer.map(campaign => ({
        label: campaign.name,
        value: campaign.code
      }))
    );

    if (campaignOffer.length) {
      setShowCampaign(true);
    } else {
      setShowCampaign(false);
    }
    setCampaignsOption(newFormattedCampaigns);
    if (selectedPlan && !isDispetchPayment) {
      setIsDispetchPayment(true)
      async function verifyIfUserHasRecommendation() {
        try {
          if (recommendation && selectedPlan && selectedPlan.basePrice === 0) {
            handleSubmitPayment();
          } else if (selectedPlan && selectedPlan.basePrice === 0) {
            handleSubmitPayment();
          }
        } catch (error) {
          console.log(error);
          setIsDispetchPayment(false)
        }
      }
      verifyIfUserHasRecommendation();
    }
  }, [selectedPlan, isDispetchPayment]);

  const maxInstallments = useMemo(() => {
    const methods = _.get(selectedPlan, 'paymentMethods', []);
    const paymentMethodSettings = methods.find(
      item => item.type === selectedPaymentMethod
    );
    const installmentsTotal = _.get(
      paymentMethodSettings,
      'maxInstallment',
      null
    );
    return installmentsTotal;
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (
      couponData &&
      selectedPlan.basePrice &&
      couponData.value &&
      couponData.type
    ) {
      const finalPrice =
        couponData.type === 'PERCENT'
          ? formatPrice(
            Number(selectedPlan.basePrice) -
            Number(selectedPlan.basePrice) *
            (Number(couponData.value) / 100)
          )
          : formatPrice(
            Number(selectedPlan.basePrice) - Number(couponData.value)
          );

      setPriceWithDiscount(finalPrice);
    } else {
      setPriceWithDiscount(undefined);
    }
  }, [couponData, selectedPlan]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      onSelectedPaymentMethod(selectedPaymentMethod);
    }
  }, [selectedPaymentMethod, selectedPlan]);

  return (
    selectedPlan && (
      <Styles.Container>
        {showHeader && (
          <Styles.NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
            <a className="navbar-brand">
              <img src={logo} alt="logo_lit" />
            </a>
            <button type="button" onClick={() => logout()}>
              SAIR
            </button>
          </Styles.NavLit>
        )}
        {selectedPlan.basePrice > 0 && !finishedPayment && (
          <div className="container">
            {paymentMethods.length > 1 && (
              <div className="row">
                <div className="col-sm-12 col-lg my-1 d-flex align-items-center justify-content-between">
                  <Styles.ContainerPlanTypes>
                    {paymentMethods.length > 1 && paymentMethods.map((paymentMethod, index) => (
                      <Tooltip title={paymentMethod.name} placement="top" key={index}>
                        <Styles.ButtonPlan
                          key={index}
                          id={index}
                          onlyPaymentMethod={paymentMethods.length === 1}
                          onClick={() =>
                            onSelectedPaymentMethod(paymentMethod.type)
                          }
                          title={paymentMethod.name}
                          selected={selectedPaymentMethod === paymentMethod.type}
                          active={selectedPaymentMethod === paymentMethod.type}
                          methodType={paymentMethod.type}
                        >
                          <Styles.AndroidPaymentButton
                            type="button"
                            active={selectedPaymentMethod === paymentMethod.type}
                          >
                            {PAYMENT_METHODS_ICONS[paymentMethod.type]}

                          </Styles.AndroidPaymentButton>

                          <span>
                            <Styles.CheckerIcon className="checked" />
                          </span>

                        </Styles.ButtonPlan>
                      </Tooltip>
                    ))}
                  </Styles.ContainerPlanTypes>
                  {selectedPaymentMethod && selectedPaymentMethod === 'INVOICE' && (
                    <Styles.TextInVoice>
                      <span>Importante:</span> Seu acesso será liberado em até 72 horas devido a compensação bancária do seu boleto. Caso queira acessar agora, utilize a opção de cartão de crédito ou PIX.
                    </Styles.TextInVoice>
                  )}
                </div>
              </div>
            )}

            <div className="row">
              <div className="col mb-2">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-12 d-flex align-items-center">
                    <Styles.PaymentResume
                      style={{ marginTop: paymentMethods.length > 1 ? 0 : 30 }}
                    >
                      <Styles.PaymentTotalPriceView>
                        <Styles.PriceResumeDescription>
                          <Styles.PlanName className='col-12'>{selectedPlan.name}</Styles.PlanName>
                          {!installments.active && (
                            <small style={{ fontSize: 12 }}>
                              Total do pagamento
                            </small>
                          )}
                          {installments.active && !hideTotalPriceLabel && (
                            <small style={{ fontSize: 12 }}>
                              De R$ <del>{selectedPlan.formattedBasePrice}</del>{' '}
                              por
                            </small>
                          )}

                          <Styles.BoxPrice>
                            {!!priceWithDiscount && (
                              <Styles.TotalPriceView>
                                {installments.active
                                  ? installments.totalPriceFromInstallment
                                  : `R$ ${priceWithDiscount}`}
                              </Styles.TotalPriceView>
                            )}

                            {!priceWithDiscount && (
                              <Styles.TotalPriceView>
                                {installments.active
                                  ? installments.totalPriceFromInstallment
                                  : `R$ ${selectedPlan.formattedBasePrice}`}
                              </Styles.TotalPriceView>
                            )}

                            {maxInstallments > 1 && (
                              <small
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: 12
                                }}
                              >
                                Em até {maxInstallments}x sem juros
                              </small>
                            )}
                          </Styles.BoxPrice>


                        </Styles.PriceResumeDescription>

                      </Styles.PaymentTotalPriceView>

                    </Styles.PaymentResume>
                  </div>
                  <Styles.CupomBox setMarginTop={paymentMethods.length === 1} showBorder={showCupomForm}>
                    {!showCupomForm && (
                      <Styles.ShowCouponButton
                        style={{ width: "100%" }}
                        onClick={() => setShowCupomForm(true)}
                      >
                        <p>Possui cupom?</p> <DownArrowAlt />
                      </Styles.ShowCouponButton>
                    )}
                    {showCupomForm && (
                      <>
                        <Styles.ItemFormField>
                          <Styles.InputText
                            placeholder="Insira o cupom"
                            name="coupon"
                            value={couponCode}
                            onChange={value => {
                              setCoupon(value.target.value);
                            }}
                            className="form-control"
                          />
                        </Styles.ItemFormField>
                        <Styles.ConfirmButton
                          style={{ width: "100px" }}
                          className={`btn ${isLoadingCoupon && 'btn-loading'}`}
                          handleSubmit={() => handleApplyCoupon()}
                          disabled={isLoadingCoupon || !couponCode}
                          onClick={() => handleApplyCoupon()}
                        >
                          Aplicar
                        </Styles.ConfirmButton>
                        <Styles.CloseIcon onClick={() => setShowCupomForm(false)} />
                      </>
                    )}
                  </Styles.CupomBox>
                </div>

              </div>
            </div>

            {defaultPlan || showCampaign && (
              <div className="row">
                <div className="col mb-3">
                  <div className="row">
                    <div className="col">
                      <CampaignOffer
                        showCampaign={showCampaign}
                        selectedPlan={selectedPlan}
                        setPlanCampaign={setPlanCampaign}
                      />
                    </div>
                  </div>

                  {defaultPlan && (
                    <Styles.ContentCenter>
                      <Styles.CampaignBackButton
                        type="button"
                        onClick={() => {
                          setSelectedPlan(defaultPlan);
                          setDefaultPlan(null);
                        }}
                        style={{ fontSize: 20 }}
                      >
                        Voltar para o plano
                      </Styles.CampaignBackButton>
                    </Styles.ContentCenter>
                  )}
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                {verifyCouponIsFull(couponData) && (
                  <FullPercentDiscount
                    selectedPlan={selectedPlan}
                    onPaymentSuccess={handlePaymentSuccess}
                    couponCode={couponCode}
                  />
                )}
                {!verifyCouponIsFull(couponData) && !loading && renderForm}

                {loading && <Loading width="30" />}
              </div>
            </div>
          </div>
        )}

        {selectedPlan.basePrice === 0 && !finishedPayment && (
          <div className="row">
            <div className="col-12">
              <Loading width="30" message="Processando..." />
            </div>
          </div>
        )}

        {finishedPayment && (
          <div className="row">
            <div className="col-12">
              <Loading width="30" message="Processando..." />
            </div>
          </div>
        )}
      </Styles.Container>
    )
  );
}

export default PaymentForm;
