import React, { useState, useMemo } from 'react';
import { addMinutes, isAfter } from 'date-fns';
import _ from 'lodash';

import Form from './Form';
import { gateway } from '../../../../utils/api';

import handleRequestErrorMessage from '../../helpers/handleRequestErrorMessage';
import handleFormSubmitHelper from '../../helpers/handleFormSubmit';
import extractTermTemplateReference from '../../helpers/extractTermTemplateReference';
import createUserTerm from '../../helpers/createUserTerm';
import { createVindiCustomer } from "../../../../utils/vindi";

function PixForm({ selectedPlan, onPaymentSuccess, couponCode }) {

  const [generatingTerm, setGeneratingTerm] = useState(false);
  const [alreadyGeneratedTerm, setAlreadyGeneratedTerm] = useState(false);
  const [userTerm, setUserTerm] = useState(null);
  const [showTerm, setShowTerm] = useState(false);

  const termTemplateReference = useMemo(
    () =>
      extractTermTemplateReference({
        selectedPlan,
        paymentMethod: 'PIX'
      }),

    [selectedPlan]
  );

  const pixDataStr = localStorage.getItem('pixData');
  let pixData = pixDataStr ? JSON.parse(pixDataStr) : null;

  if (pixData) {
    const datePix = new Date(pixData.date);
    const expiredPix = isAfter(new Date(), addMinutes(datePix, 15));
    if (expiredPix) {
      pixData = null;
    }
  }

  const initialCaptureId = pixData ? pixData.idCapture : null;
  const initialQrCode = pixData
    ? { data: pixData.codigo, url: pixData.url, date: pixData.date }
    : null;

  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState(initialQrCode);
  const [captureId, setCaptureId] = useState(initialCaptureId);
  const [paymentResponseData, setPaymentResponseData] = useState(null);

  async function handleSubmitPayment(formValues) {
    try {
      setLoading(true);
      const createCustomerData = {
        name: `${formValues.shopperFirstName.trim()} ${formValues.shopperLastName.trim()}`,
        address: {
          street: formValues.street,
          number: formValues.number,
          additional_details: formValues.additional_details,
          zipcode: formValues.zipcode,
          neighborhood: formValues.neighborhood,
          city: formValues.city,
          state: formValues.state,
          country: formValues.country
        },
        cpf: formValues.document,
        paymentMethod: 'PIX',
      }

      await createVindiCustomer(createCustomerData).catch(() => {
        throw new Error('Não foi possível processar o pagamento. Por favor, verifique os dados preenchidos!');
      });
      const body = {
        crCodeCoupon: couponCode,
        type: 'PIX',
        offer: {
          id: selectedPlan.code
        },
        shopper: {
          firstName: formValues.shopperFirstName,
          lastName: formValues.shopperLastName,
          document: formValues.document
        },
        installments: 1,
        term: userTerm
          ? {
            mediaCode: _.get(userTerm, 'ox_standard.ox_identification.cr_code'),
            templateCode: termTemplateReference
          }
          : null,
        sellerCode: localStorage.getItem('sellerCode'),
        sellerLinkId: localStorage.getItem('sellerLinkId'),
      };

      const url = selectedPlan.alreadyPurchased
        ? '/contract/contract/updatePaymentMethodVindi'
        : '/contract/contract/processSubscription';
      const method = selectedPlan.alreadyPurchased ? 'put' : 'post';

      const paymentResponse = await gateway[method](url, body);

      const { paymentInfo } = paymentResponse;
      const transactionCode = _.get(
        paymentResponse,
        'transactions.0.ox_standard.ox_identification.cr_code',
        null
      );

      const today = new Date();

      setQrCode({
        data: paymentInfo.qrCodeData,
        url: paymentInfo.url,
        date: today
      });
      const captureId = paymentResponse.captures[0]._id;
      setCaptureId(captureId);
      setPaymentResponseData({ ...paymentResponse, transactionCode });

      const pixData = {
        url: paymentInfo.url,
        codigo: paymentInfo.qrCodeData,
        idCapture: captureId,
        date: today,
        offerId: selectedPlan.code,
        installments: 1,
        term: userTerm
          ? {
            mediaCode: _.get(userTerm, 'ox_standard.ox_identification.cr_code'),
            templateCode: termTemplateReference
          }
          : null
      };

      localStorage.setItem('pixData', JSON.stringify(pixData));
    } catch (error) {
      handleRequestErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleGenerateTerm(values) {
    try {
      if (alreadyGeneratedTerm) {
        setShowTerm(true);
        return;
      }

      setGeneratingTerm(true);
      const response = await gateway.post(
        '/webbff/lit_app_web/term/generatePaymentTerm',
        {
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        }
      );

      if (!response) {
        setUserTerm(null);
        setShowTerm(false);
        setAlreadyGeneratedTerm(false);
        setGeneratingTerm(false);
        handleSubmitPayment(values);
        return;
      }

      setUserTerm(response);
      setShowTerm(true);
      setAlreadyGeneratedTerm(true);
    } catch (error) {
      console.log(error);
      setUserTerm(null);
      setShowTerm(false);
      setAlreadyGeneratedTerm(false);
      setGeneratingTerm(false);
      handleSubmitPayment(values);
    } finally {
      setGeneratingTerm(false);
    }
  }

  async function handleOpenTerm() {
    try {
      setGeneratingTerm(true);
      if (!userTerm) {
        const response = await createUserTerm({
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        });
        setUserTerm(response);
      }
    } catch (error) {
      handleRequestErrorMessage(error);
    } finally {
      setGeneratingTerm(false);
    }
  }

  return (
    <Form
      handleGenerateTerm={handleGenerateTerm}
      onSubmit={formValues =>
        handleFormSubmitHelper({
          selectedPlan,
          processPaymentFunction: handleSubmitPayment,
          formValues
        })
      }
      onPaymentSuccess={onPaymentSuccess}
      selectedPlan={selectedPlan}
      qrCode={qrCode}
      captureId={captureId}
      paymentResponse={paymentResponseData}
      userTerm={userTerm}
      onOpenTerm={handleOpenTerm}
      generatingTerm={generatingTerm}
      showTerm={showTerm}
      setShowTerm={setShowTerm}
      loading={loading}
    />
  );
}

export default PixForm;
