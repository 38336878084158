import React, { useEffect } from 'react';
import history from '../../utils/history';
import { gateway } from '../../utils/api';

export function CheckAddressOnboarding() {
  async function haveAddressCompletedOnOnboarding() {
    try {
      const response = await gateway.get('/onboarding/steps');
      const tenant = localStorage.getItem('tenant');
      const hasLitBusiness = tenant === 'LIT_BUSINESS';
      if (!hasLitBusiness) {
        return;
      }

      // Validação 1: Verifica se todos os itens possuem bf_concluded: true ou bf_skipaddress: true
      const allStepsCompletedOrSkipped = response.every(item => {
        // Considera equivalente a true se bf_required é false
        if (item.bf_required === false) {
          return true;
        }
        // Avalia as demais condições
        return (
          (item.bf_concluded === true && item.bf_required === true) ||
          item.bf_skipaddress === true
        );
      });

      if (!allStepsCompletedOrSkipped) {
        history.push('/onboarding');
        return; // Interrompe a execução para evitar que as outras regras sejam executadas
      }

      // Validação 2: Verificação do tenant e dados de endereço
      const stepWithPersonalData = response.find(
        item => item.wx_page === 'PERSONAL_DATA'
      );

      if (
        stepWithPersonalData &&
        stepWithPersonalData.bf_concluded !== true &&
        hasLitBusiness
      ) {
        const areFieldsPresent = obj => {
          const { wx_address, wx_addressnumber, wx_country, wx_zipcode } =
            obj.ox_personaldata || {};
          return wx_address && wx_addressnumber && wx_country && wx_zipcode;
        };

        if (!areFieldsPresent(stepWithPersonalData)) {
          history.push('/onboarding');
          return;
        }
      }
    } catch (error) {
      console.error('Error during onboarding address check:', error);
    }
  }
  async function checkForcePasswordUpdate() {
    try {
      const email = localStorage.getItem('ra');
      if (!email) return;

      const filter = {
        "ox_specific.rk_user.rk_reference": email,
      };

      const credentialResponse = await gateway.post(
        'credential/credential/search',
        { filter }
      );

      const hasForcePasswordUpdate = credentialResponse.find(
        item => item.ox_specific.bf_force_password_update === true
      );

      if (hasForcePasswordUpdate) {
        localStorage.setItem('hasForcePasswordUpdate', 'true');
        history.push('/updated-password');
      } else {
        localStorage.setItem('hasForcePasswordUpdate', 'false');
      }
    } catch (error) {
      console.error('Error checking force password update:', error);
    }
  }


  useEffect(() => {
    const { pathname } = window.location;
    const validPaths = [
      '/',
      '/consultaCpf',
      '/planos',
      '/payment',
      '/cadastroPerfil',
      '/confirmarEmail',
      '/logout',
      '/invoicePaymentView',
      '/updated-password'
    ];
    const isPathValid = validPaths.includes(pathname);
    const hasForcePasswordUpdate = localStorage.getItem('hasForcePasswordUpdate')
    if (!isPathValid) {
      haveAddressCompletedOnOnboarding();
    }
    if (pathname === '/home') {
      checkForcePasswordUpdate();
    }
    if (hasForcePasswordUpdate === 'true') {
      history.push('/updated-password');
    }
  }, [window.location.pathname]);

  return null;
}
