import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import history from '../../utils/history';
import { gateway } from '../../utils/api';
import { generateSecurePassword } from '../../utils/functions';
import {
  InputLabel,
  InputWrap,
  InputText
} from '../Styles/FormStyle';
import NavBar from './NavBar'
import Footer from './Footer'

const UpdatedPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [feedback, setFeedback] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    symbol: false,
    match: false,
    different: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password) => {
    const feedback = {
      length: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /\d/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      different: password !== oldPassword,
    };

    setFeedback((prev) => ({ ...prev, ...feedback }));
  };

  const handlePasswordChange = (e) => {
    // eslint-disable-next-line prefer-destructuring
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    // eslint-disable-next-line prefer-destructuring
    const value = e.target.value;
    setConfirmPassword(value);
    setFeedback((prev) => ({ ...prev, match: value === newPassword }));
  };

  const updateCredential = async (email) => {
    try {
      await gateway.put(`credential/credential/togglePasswordUpdateFlagtService`, {
        email,
        shouldForceUpdate: false
      });
      localStorage.setItem('hasForcePasswordUpdate', false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const email = localStorage.getItem('ra');
    if (!email) {
      setErrorMessage('Erro ao obter o e-mail do usuário.');
      return;
    }


    setIsLoading(true);
    try {
      await gateway.post('/user/changePasswordCheckingOld', {
        email,
        newPassword: generateSecurePassword(newPassword),
        oldPassword: generateSecurePassword(oldPassword),
      });

      await updateCredential(email);
      history.push('/home');
    } catch (error) {
      setErrorMessage(
        'Erro ao atualizar a senha. Tente novamente.'
      );
    } finally {
      setIsLoading(false); 
    }
  };

  const isFormValid =
    feedback.length &&
    feedback.upperCase &&
    feedback.lowerCase &&
    feedback.number &&
    feedback.symbol &&
    feedback.match &&
    feedback.different &&
    oldPassword;

  return (
    <>
    <NavBar />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          width: '400px',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          background: '#f9f9f9',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >


        <h2 style={{ textAlign: 'center', color: 'rgb(39, 170, 225)', marginTop: '5px', marginBottom:'5px' }}>Atualizar Senha</h2>

        <form onSubmit={(e) => e.preventDefault()}>
        <InputWrap
            error={!oldPassword}
            style={{ marginBottom: '.9rem', marginTop:'10px' }}
          >
            <InputText
              type="password"
              placeholder="Senha Antiga"
              className="form-control"
              id="inputOldPassword"
              value={oldPassword}
              name="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
              autoComplete="off"
              required
            />
            <InputLabel htmlFor="inputOldPassword">Senha Antiga</InputLabel>
          </InputWrap>

          <InputWrap
            error={!feedback.length}
            style={{ marginBottom: '.9rem' }}
          >
            <InputText
              type="password"
              placeholder="Nova Senha"
              className="form-control"
              id="inputNewPassword"
              value={newPassword}
              name="newPassword"
              onChange={handlePasswordChange}
              autoComplete="off"
              required
            />
            <InputLabel htmlFor="inputNewPassword">Nova Senha</InputLabel>
          </InputWrap>

          <InputWrap
            error={!feedback.match}
            style={{ marginBottom: '.9rem' }}
          >
            <InputText
              type="password"
              placeholder="Confirmar Nova Senha"
              className="form-control"
              id="inputConfirmPassword"
              value={confirmPassword}
              name="confirmPassword"
              onChange={handleConfirmPasswordChange}
              autoComplete="off"
              required
            />
            <InputLabel htmlFor="inputConfirmPassword">Confirmar Nova Senha</InputLabel>
          </InputWrap>

          <div style={{ marginBottom: '15px' }}>
            <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>Critérios de Validação:</p>
            <ul style={{ paddingLeft: '20px' }}>
              <li style={{ color: feedback.length ? 'green' : 'red' }}>Pelo menos 8 caracteres</li>
              <li style={{ color: feedback.upperCase ? 'green' : 'red' }}>Pelo menos 1 letra maiúscula</li>
              <li style={{ color: feedback.lowerCase ? 'green' : 'red' }}>Pelo menos 1 letra minúscula</li>
              <li style={{ color: feedback.number ? 'green' : 'red' }}>Pelo menos 1 número</li>
              <li style={{ color: feedback.symbol ? 'green' : 'red' }}>Pelo menos 1 símbolo</li>
              <li style={{ color: feedback.different ? 'green' : 'red' }}>Senha diferente da anterior</li>
              <li style={{ color: feedback.match ? 'green' : 'red' }}>Senhas coincidem</li>
            </ul>
          </div>

          {errorMessage && (
            <div style={{ marginBottom: '15px', color: 'red', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}

          <button
            type="button"
            onClick={handleSubmit}
            disabled={!isFormValid || isLoading}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: isFormValid && !isLoading ? 'rgb(39, 170, 225)' : '#ccc',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: isFormValid && !isLoading ? 'pointer' : 'not-allowed',
            }}
          >
            {isLoading ? 'Carregando...' : 'Atualizar Senha'}
          </button>
        </form>
      </div>
    </div>
    <Footer />
    </>

  );
};

export default UpdatedPassword;
