import React, { PureComponent } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import { Creators as VoucherActions } from '../../store/ducks/voucher';
import { Creators as AlertActions } from '../../store/ducks/alert/actions';
import {
  identifyLogin,
  generateSecurePassword,
  toastDefaultOptions
} from '../../utils/functions';
import SocialLogin from '../SocialLogin';
import NovaConta from './NovaConta';
import NotifyService from '../../utils/NotifyService';
import api, { gateway } from '../../utils/api';
import { isAuthenticated } from '../../utils/auth';
import LeftSideBar from './LeftSideBar';
import LoginForm from './LoginForm';
import Loading from '../Loading/Loading';

import { fetchOnboardingPages } from '../../business/onboarding';

import { LinkLogin, LoginBox, Wrapper, TextH3Header } from './styles';
import adsTracking from '../../utils/adsTracking';
import spinner from '../../images/paul_loading.gif';

const styles = {
  loginWith: {
    marginTop: '15px'
  }
};

class Login extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      lembrarSenha: false,
      errorMessage: null,
      litpass: false,
      isVocacao: false,
      planByUrl: false,
      showSocialLogin: true,
      routerParams: null,
      dataLogin: null,
      loading: false,
      promo: false,
      originParam: null,
      integrationErrorMessage: null,
      goToRegisterURL: '/cadastroPerfil'
    };

    this.notif = new NotifyService();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      if (this.state.loading) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ dataLogin: null });
      }
    }
  }

  async componentDidMount() {
    const { location, removeSmartBar } = this.props;
    const { router } = this.context;
    const voucherAtivo = JSON.parse(localStorage.getItem('voucherAtivo'));

    let activeContract = false;
    if (localStorage.getItem('activeContract') !== 'undefined') {
      activeContract = JSON.parse(localStorage.getItem('activeContract'));
    }

    if (localStorage.getItem('idProfile') && !voucherAtivo && !activeContract) {
      router.history.push('/planos');
      return;
    }

    const routerParams = queryString.parse(router.history.location.search);
    if (
      localStorage.getItem('idProfile') &&
      (voucherAtivo || activeContract) &&
      !routerParams.loginWithoutPassword
    ) {
      router.history.push('/home');
    }

    localStorage.removeItem('countDown');
    localStorage.removeItem('company');
    localStorage.removeItem('certificates');
    localStorage.removeItem('cuponDesconto');
    localStorage.removeItem('urlOriginParam');
    localStorage.removeItem('o');

    removeSmartBar();
    const params = new URLSearchParams(location.search);
    const planByUrl = params.get('plan');
    const typeByUrl = params.get('type') || null;
    const hideForm = params.get('hf') === 'true' || false;
    const code = params.get('code');
    try {
      if (code) {
        this.setState({ loading: true });
        const url = `webbff/lit_app_web/login/oauth-anglo-signin?authServer=${'plurall'}&code=${code}`;
        const response = await gateway.post(url);
        this.doLoginSuccess(response);
      }
    } catch (error) {
      const errorMessage = _.get(
        error,
        'response.data.message',
        'Erro ao processar autenticação com Anglo.'
      );
      this.setState({ integrationErrorMessage: errorMessage });
      swal({
        title: `Ops, ocorreu um erro.`,
        text: errorMessage,
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'btn-alert btn-alert-confirm-onboarding'
          }
        }
      });
    } finally {
      this.setState({ loading: false });
    }

    const cuponDesconto = params.get('cupom-desconto');

    if (cuponDesconto) {
      localStorage.setItem('cuponDesconto', cuponDesconto);
    }

    const plans = params.get('plans');
    if (plans) {
      localStorage.setItem('urlPlansParam', plans);
    }

    // /* Implementation redirect */
    const origemParam = params.get('o');
    this.setState({ originParam: origemParam });

    if (origemParam) {
      localStorage.setItem('urlOriginParam', origemParam || '');
      localStorage.setItem('urlRedirect', origemParam || '');
      sessionStorage.setItem('urlOriginParam', origemParam || '');
    }

    if (planByUrl) {
      localStorage.setItem('planByUrl', planByUrl);
      this.setState({ planByUrl: true });
    }

    if (hideForm) {
      localStorage.setItem('hideForm', hideForm);
      this.setState({ hideForm });
    }

    localStorage.setItem('typeByUrl', typeByUrl);

    if (location.state) {
      localStorage.setItem('locationState', JSON.stringify(location.state));
    }
    localStorage.setItem('savedOneSignal', false);
    const remember = localStorage.getItem('remember');

    if (location.pathname.includes('litpass')) {
      this.setState({ litpass: true });
    }

    if (location.pathname.includes('vocacao')) {
      this.setState({ isVocacao: true });
      localStorage.setItem('urlOriginParam', 'vocacao');
    }

    if (router.history.location.search) {
      this.setState({
        routerParams: queryString.parse(router.history.location.search)
      });
    }

    localStorage.removeItem('paymentInfo');
    const auth = isAuthenticated();
    if (remember === '1' && auth) {
      identifyLogin(localStorage.getItem('idLogin'));
      this.notif.syncIdOneSignal(localStorage.getItem('idProfile'));
      this.verifyNavigation();
    }

    if (location.hash && location.hash.includes('id_token')) {
      this.getTokenAdfs(location.hash.split('=')[1]);
    }
    this.toastExpiredToken();
    this.loadUrlRedirect();
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  toastExpiredToken = () => {
    const expired = localStorage.getItem('redirectExpired');
    if (expired) {
      toast(expired, {
        ...toastDefaultOptions,
        type: 'warning',
        closeButton: false
      });
      localStorage.removeItem('redirectExpired');
    }
  };

  setItensLocalStorage = (dados, adfs = false) => {
    const { lembrarSenha } = this.state;
    if (!adfs && dados.countDown && Object.keys(dados.countDown).length > 0) {
      localStorage.setItem('countDown', JSON.stringify(dados.countDown));
    }

    if (dados.certificates && !dados.firstLogin && !dados.voucherAtivo) {
      localStorage.setItem('certificates', dados.certificates);
    }

    localStorage.setItem('activeContract', dados.activeContract);
    localStorage.setItem('litpass', dados.litpass);
    localStorage.setItem('finishOnBoarding', !dados.onBoardIncomplete);
    localStorage.setItem('57e0854551b1dca003c67c384c37a346', false);
    localStorage.setItem('firstName', dados.firstName);
    localStorage.setItem('lastName', dados.lastName);
    localStorage.setItem('ra', dados.ra);
    localStorage.setItem('idLogin', dados.idLogin);
    localStorage.setItem('idProfile', dados.idProfile);
    localStorage.setItem('idMember', dados.groupMember);
    localStorage.setItem('idOwner', dados.ownerGroup);
    localStorage.setItem('idRA', dados.idRA);
    localStorage.setItem('voucherAtivo', dados.voucherAtivo);
    localStorage.setItem('freeUser', dados.freeUser);
    localStorage.setItem('libraryUser', dados.libraryUser);
    localStorage.setItem('socialUser', dados.socialUser);
    localStorage.setItem('remember', lembrarSenha ? 1 : 0);
    localStorage.setItem('firstLogin', dados.firstLogin);
    localStorage.setItem('completedPersonality', dados.completedPersonality);
    localStorage.setItem('modalNotas', dados.messagesCount > 0);
    localStorage.setItem('showPaul', dados.showPaul);
    localStorage.setItem('hasForcePasswordUpdate', dados.hasForcePasswordUpdate);
    localStorage.setItem(
      'dateLogin',
      `${new Date().getDate()}/${new Date().getMonth()}`
    );
    localStorage.setItem('onBoardIncomplete', dados.onBoardIncomplete);
    localStorage.setItem('naRealUser', dados.naRealUser);

    if (dados.paymentInfo.valorDesconto) {
      localStorage.setItem('paymentInfo', JSON.stringify(dados.paymentInfo));
    }
    if (dados.mensagemErroPagamento !== null) {
      localStorage.setItem('57e0854551b1dca003c67c384c37a346', true);
    }
  };

  loadUrlRedirect = (goToScreen = false) => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const origemParam = params.get('o');
    let goToRegisterURL = '/cadastroPerfil';
    const loginTenant = localStorage.getItem('loginTenant');
    const litPassCheck = location.pathname.includes('litpass');
    const vocacaoCheck = location.pathname.includes('vocacao');
    if (litPassCheck) {
      goToRegisterURL += `LitPass`;
    } else if (vocacaoCheck) {
      goToRegisterURL += `Vocacao`;
    }

    if (loginTenant) {
      goToRegisterURL += `/${loginTenant.toLowerCase()}`;
    }

    if (origemParam) {
      goToRegisterURL += `?o=${origemParam}`;
    }

    this.setState({ goToRegisterURL });
    if (goToScreen) {
      const { router } = this.context;
      router.history.push(goToRegisterURL);
    }
  };

  doUpdateCompanyLogin = email => {
    try {
      const origin = localStorage.getItem('urlOriginParam') || '';
      if (origin.toLocaleLowerCase().includes('b2c-curso')) {
        gateway.put(`/webbff/lit_app_web/login/doUpdateCompanyLogin`, {
          email
        });
      }
    } catch (error) {
      console.log('Não foi possível atualizar a company do usuário.');
    }
  };

  doLoginSuccess = (userInfos, adfs = false) => {
    const email = localStorage.getItem('ra');
    this.doUpdateCompanyLogin(userInfos.ra);
    const { getProfileRequest } = this.props;
    if (userInfos.idProfile) {
      this.notif.syndIdOneSignalMongo();

      this.notif.syncIdOneSignal(userInfos.idProfile);
      getProfileRequest(userInfos.idProfile);
      this.setItensLocalStorage(userInfos, adfs);
      this.setState({ errorMessage: null, loading: false });

      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'login',
        eventCategory: 'login_flow',
        eventLabel: `origin:${localStorage.getItem('urlRedirect')}`,
        // callback: () => {
        //   this.verifyNavigation(userInfos);
        // }
        // This part was commented because was in loop
      });

      this.verifyNavigation(userInfos);
    } else {
      identifyLogin(null, email);
    }
  };

  verifyOnboardingPage = async () => {
    this.setState({ loading: true });
    const onBoardingData = await fetchOnboardingPages();
    return onBoardingData
      .filter(item => item.bf_required)
      .some(i => !i.bf_concluded);
  };

  verifyRedirectPlan = async () => {
    const urlOriginParam = localStorage.getItem('urlOriginParam');
    if (!urlOriginParam) return false;

    this.setState({ loading: true });
    const loginTenant = localStorage.getItem('loginTenant');
    let url = `/webbff/lit_app_web/payment/plan/validateRedirectPlan?origin=${urlOriginParam}`;
    url = loginTenant ? `${url}&loginTenant=${loginTenant}` : url;
    return gateway.get(url);
  };

  fetchTenancy = async () => {
    try {
      const response = await gateway('/webbff/shared/tenancy/profiles');
      return response
    } catch (error) {
      console.error(error);
    }
  }

  verifyNavigation = async userInfos => {
    const loginTenant = localStorage.getItem('loginTenant');
    if(loginTenant) {
      const tenancies = await this.fetchTenancy().catch(() => []);
      const foundTenant = tenancies.find(item => item.tenancy === loginTenant);
      if(!foundTenant) {
        const { router } = this.context;
        router.history.push('/planos');
        return
      }
    }
    
    
    try {
      const { router } = this.context;
      let {
        location: { state }
      } = this.props;

      if (!state) {
        state = JSON.parse(localStorage.getItem('locationState'));
      }

      this.setState({ dataLogin: null });
      localStorage.setItem('redirectMyCourse', userInfos.redirectMyCourse);
      const redirectByCadastroPerfil = localStorage.getItem(
        'redirectByCadastroPerfil'
      );
      const tenant = localStorage.getItem('tenant');
      if (userInfos) {
        if (!userInfos.emailVerified) {
          router.history.push('/confirmarEmail');
        } else if (
          !userInfos.voucherAtivo &&
          !userInfos.certificates &&
          !userInfos.activeContract &&
          !userInfos.cpf
        ) {
          router.history.push('/consultaCpf');
        } else if (
          !userInfos.voucherAtivo &&
          !userInfos.activeContract &&
          userInfos.cpf
        ) {
          router.history.push('/planos');
        } else if (
          !userInfos.voucherAtivo &&
          !userInfos.activeContract &&
          userInfos.certificates
        ) {
          router.history.push('/planos');
        } else if (
          userInfos.mensagemErroPagamento ||
          redirectByCadastroPerfil
        ) {
          if (redirectByCadastroPerfil) {
            localStorage.removeItem('redirectByCadastroPerfil');
          }
          router.history.push('/perfilAluno');
        } else if(tenant === 'INTERPLAYERS') {
          router.history.push('/home');
        } else {
          const onboardingPages = await this.verifyOnboardingPage();
          const redirectPlan = await this.verifyRedirectPlan();
          if (onboardingPages && !userInfos.finishOnBoarding) {
            router.history.push('/onboarding');
          } else {
            this.redirectToPage(userInfos, state, redirectPlan);
          }
        }
      } else if (
        !localStorage.getItem('voucherAtivo') &&
        (!localStorage.getItem('activeContract') ||
          localStorage.getItem('activeContract') === 'undefined')
      ) {
        router.history.push('/planos');
      } else {
        router.history.push('/home');
      }
      localStorage.removeItem('locationState');
      return
    } catch (error) {
      const { router } = this.context;
      router.history.push('/home');
      if (userInfos) {
        if (!userInfos.emailVerified) {
          router.history.push('/confirmarEmail');
        } else if (
          !userInfos.voucherAtivo &&
          !userInfos.certificates &&
          !userInfos.activeContract &&
          !userInfos.cpf
        ) {
          router.history.push('/consultaCpf');
        } else if (
          !userInfos.voucherAtivo &&
          !userInfos.activeContract &&
          userInfos.certificates
        ) {
          router.history.push('/planos');
        }
      } else if (
        !localStorage.getItem('voucherAtivo') &&
        (!localStorage.getItem('activeContract') ||
          localStorage.getItem('activeContract') === 'undefined')
      ) {
        router.history.push('/planos');
      } else {
        router.history.push('/home');
      }
    }
  };

  redirectToPage = (data, state, redirectPlan) => {
    const { router } = this.context;
    const urlOriginParam = localStorage.getItem('urlOriginParam');
    if (data.showNps) {
      router.history.push('/avaliacao');
    } else if (urlOriginParam && data.activeContract) {
      if (redirectPlan) {
        router.history.push({
          pathname: `/perfilAluno`,
          state: { openModal: true }
        });
      } else {
        router.history.push('/home');
      }
    } else if (data.redirectMyCourse) {
      router.history.push('/cursos');
    } else {
      this.verifyRedirect(router, state);
    }
  };

  verifyRedirect = (router, state) => {
    if (state) {
      const {
        from: { pathname, search }
      } = state;
      const params = new URLSearchParams(search);
      const objectCrCode = params.get('objectCode');
      if (objectCrCode) {
        router.history.push(
          {
            pathname
          },
          {
            object: objectCrCode
          }
        );
      } else {
        router.history.push(pathname);
      }
    } else {
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const redirectParam = params.get('redirect');

      const redirectUrl =
        redirectParam && redirectParam.startsWith('/')
          ? redirectParam
          : `/${redirectParam || 'home'}`;

      router.history.push(redirectUrl);
    }

    localStorage.removeItem('locationState');
  };

  getTokenAdfs = async code => {
    try {
      const ra = localStorage.getItem('ra');
      const data = {
        username: ra,
        password: code
      };
      this.setState({ loading: true });
      this.logar(data, true);
    } catch (error) {
      console.log(error.response);
    }
  };

  logar = async (data, adfs) => {
    try {
      this.setState({ loading: true, dataLogin: data });

      const dataClone = Object.assign({}, data);
      dataClone.password = adfs
        ? dataClone.password
        : generateSecurePassword(dataClone.password);

      const body = {
        ...dataClone
      };
      const url = `/webbff/lit_app_web/login/signin?from=portal`;
      const response = await gateway.post(url, body);
      // eslint-disable-next-line no-undef
      const event = new CustomEvent('userLoggedIn', { detail: 'isLogged' });
      window.dispatchEvent(event);
      this.doLoginSuccess(response, adfs);
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({ errorMessage: error.response.data.message });
      }
      this.setState({ loading: false });
    } finally {
    
    }
  };

  loginWithoutPassword = async data => {
    try {
      this.setState({ loading: true, dataLogin: data });
      const body = {
        username: data.email
      };

      const url = `/webbff/lit_app_web/login/signinWithoutPassword`;
      const response = await gateway.post(url, body);
      localStorage.setItem('studyGroupCode', data.studyGroup);
      localStorage.setItem('tenant', data.tenant);
      localStorage.setItem('loginWithoutPassword', true);
      localStorage.setItem('cameFromTheAdmin', true);
      this.doLoginSuccess(response);
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({ errorMessage: error.response.data.message });
      }
      this.setState({ loading: false });
    } finally {
     
    }
  };

  handleSocialLogin = async social => {
    try {
      this.setState({ loading: true });
      const data = {
        ...social,
        origin: localStorage.getItem('urlRedirect') || ''
      };
      const url = `/webbff/lit_app_web/login/socialSignin`;
      const response = await gateway.post(url, data);
      this.doLoginSuccess(response);
    } catch (error) {
      this.setState({ errorMessage: error.response.data.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSocialError = data => {
    console.log('Erro \n', data);
  };

  render() {
    const { loginWith } = styles;
    const {
      errorMessage,
      showSocialLogin,
      routerParams,
      loading,
      dataLogin,
      litpass,
      isVocacao,
      planByUrl,
      hideForm,
      promo,
      originParam,
      integrationErrorMessage,
      goToRegisterURL
    } = this.state;
    const urlOriginParam = originParam ? `?o=${originParam}` : '';

    return (
      <React.Fragment>
        <div id="login" className="container-fluid">
          <div className="row">
            <LeftSideBar
              litpass={litpass}
              isVocacao={isVocacao}
              planByUrl={planByUrl}
              promo={promo}
              origin={originParam}
            />
            <div
              className="col-md-5"
              style={{
                background: '#FFF',
                zIndex: 1020
              }}
            >
              <LoginBox>
                <>
                  {loading ? (
                    <img
                      alt="loading"
                      style={{
                        maxHeight: '80%',
                        animation: 'loading-spin infinite 10s linear'
                      }}
                      src={spinner}
                    />
                  ) : (
                    <>
                      {integrationErrorMessage ? (
                        <h5 style={{ textAlign: 'center' }}>
                          {integrationErrorMessage}
                        </h5>
                      ) : (
                        <>
                          <>
                            {hideForm ? (
                              <TextH3Header className="text-center mb-4">
                                Faça seu login e conheça todas <br /> as opções
                                para continuar sua <br /> jornada de
                                aprendizagem no LIT
                              </TextH3Header>
                            ) : (
                              <TextH3Header>Bem vindo de volta</TextH3Header>
                            )}
                          </>
                          <Wrapper>
                            <div className="criar-conta">
                              {!hideForm && (
                                <p>
                                  Ainda não é aluno?{' '}
                                  <LinkLogin
                                    onClick={() => this.loadUrlRedirect(true)}
                                  >
                                    Cadastre-se aqui
                                  </LinkLogin>
                                </p>
                              )}
                            </div>
                            <LoginForm
                              routerParams={routerParams}
                              dataLogin={dataLogin}
                              doLoginSuccess={this.logar}
                              loginWithoutPassword={this.loginWithoutPassword}
                              doLoginFailure={this.handleSocialError}
                            />
                            {!!errorMessage && (
                              <div className="row">
                                <div className="col">
                                  <p style={{ color: '#e50000' }}>
                                    {errorMessage}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Wrapper>
                          {!litpass && !isVocacao && showSocialLogin && (
                            <>
                              <div className="row justify-content-center">
                                <p style={loginWith}>Ou entre com</p>
                              </div>
                              <SocialLogin
                                handleSocialLogin={this.handleSocialLogin}
                                handleSocialError={this.handleSocialError}
                              />
                            </>
                          )}

                          {!hideForm && (
                            <NovaConta
                              litpass={litpass}
                              isVocacao={isVocacao}
                              urlOriginParam={urlOriginParam}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </LoginBox>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...ProfileActions, ...VoucherActions, ...AlertActions },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
