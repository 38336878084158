/* eslint-disable radix */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-bitwise */
/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable guard-for-in */
/* eslint-disable no-cond-assign */
/* eslint-disable no-control-regex */
import React from 'react';
import md5 from 'md5';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';

import {
  FaCheckCircle,
  FaTimesCircle,
  FaClock,
  FaLock,
  FaLockOpen
} from 'react-icons/fa';
import { MdStars } from 'react-icons/md';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import api, { gateway } from './api';

import imagens from './imagens';
import { Img } from './styles';

import faviconNaReal from '../images/faviconNaReal.ico';
import history from './history';

// const Fingerprint2 = require("fingerprintjs2");

const tenantsValids = [
  'LIT',
  'ANGLO',
  'LIT_SCHOOL',
  'LIT_HOMOLOG',
  'LIT_SAMPLES',
  'HINODE',
  'IBEF',
  'SAINTPAUL',
  'LIT_BUSINESS',
  'OBJETIVO_ABC',
  'MICHAEL_PAGE',
  'EXEMPLO_2',
  'VOCACAO',
  'LIT_NEGOCIOS',
  'FARIAS_BRITO',
  'VERACEL',
  'BANCO_BRASILIA',
  'BIOLAB',
  'BOM_JESUS',
  'CERRADINHO',
  'FAIRFAX',
  'PATRIA',
  'TTG',
  'CAIXA_ASSET',
  'B2C_EXTENSAO',
  'INTERPLAYERS',
  'ARTESANAL_INVESTIMENTOS',
  'RNP',
  'ITAU_UNIBANCO',
  'ACADEMIA_INFOTEC'
];

export function isUserStorage() {
  const idProfile = localStorage.getItem('idProfile');
  const idLogin = localStorage.getItem('idLogin');
  const idMember = localStorage.getItem('idMember');
  const idOwner = localStorage.getItem('idOwner');
  const ra = localStorage.getItem('ra');
  return idProfile && idLogin && idMember && idOwner && ra;
}

export function getUserDecodedToken() {
  const token = localStorage.getItem('@Lit/token');
  if (!token) return null;
  return jwt_decode(token);
}

export function isUserAuthorized(profileType) {
  const token = localStorage.getItem('@Lit/token');
  if (!token) return false;
  const decodedToken = jwt_decode(token);
  return decodedToken.profileTypes.includes(profileType);
}

export function setItensLocalStorage(dados) {
  localStorage.setItem('finishOnBoarding', false);
  localStorage.setItem('57e0854551b1dca003c67c384c37a346', false);
  localStorage.setItem('firstName', dados.firstName);
  localStorage.setItem('lastName', dados.lastName);
  localStorage.setItem('ra', dados.ra);
  localStorage.setItem('idLogin', dados.idLogin);
  localStorage.setItem('idProfile', dados.idProfile);
  localStorage.setItem('idMember', dados.groupMember);
  localStorage.setItem('idOwner', dados.ownerGroup);
  localStorage.setItem('freeUser', dados.freeUser);
  localStorage.setItem('libraryUser', dados.libraryUser);
  localStorage.setItem('socialUser', dados.socialUser);
  localStorage.setItem('idRA', dados.idRA);
  localStorage.setItem('voucherAtivo', dados.voucherAtivo);
  localStorage.setItem('firstLogin', dados.firstLogin);
  localStorage.setItem('showPaul', dados.showPaul);
  localStorage.setItem(
    'dateLogin',
    `${new Date().getDate()}/${new Date().getMonth()}`
  );
  localStorage.setItem('completedPersonality', dados.completedPersonality);
  localStorage.setItem('onBoardIncomplete', dados.onBoardIncomplete);
  if (dados.mensagemErroPagamento !== null) {
    localStorage.setItem('57e0854551b1dca003c67c384c37a346', true);
  }
}

export function changeStatusColor(status, inProgressType) {
  let statusGrade;

  const dicionario = {
    DONE: () => (statusGrade = '#27AAE1'),
    DONE_REPROVED: () => (statusGrade = '#de1626')
  };

  if (inProgressType) {
    dicionario['IN PROGRESS'] = () => (statusGrade = '#ede01a');
  } else {
    dicionario['IN PROGRESS'] = () => (statusGrade = '#838383');
  }

  if (dicionario[status]) {
    dicionario[status]();
  } else {
    // default
    statusGrade = '#b9b9b9';
  }

  return statusGrade;
}

export function changeGradeColor(status) {
  let statusGrade;

  switch (status) {
    case 'DONE':
      statusGrade = '#27AAE1';
      break;
    case 'DONE_REPROVED':
      statusGrade = '#de1626';
      break;
    default:
      statusGrade = '#838383';
  }

  return statusGrade;
}

export function getStatusName(status) {
  let statusGrade;

  switch (status) {
    case 'DONE':
      statusGrade = 'Aprovado';
      break;
    case 'DONE_REPROVED':
      statusGrade = 'Reprovado';
      break;
    default:
      statusGrade = 'Em progresso';
  }

  return statusGrade;
}

export function renderGradeIcon(status) {
  switch (status) {
    case 'IN PROGRESS':
      return <FaClock size={24} color={changeStatusColor(status, true)} />;
    case 'DONE':
      return (
        <FaCheckCircle size={23} color={changeStatusColor(status, true)} />
      );
    case 'DONE_REPROVED':
      return (
        <FaTimesCircle size={23} color={changeStatusColor(status, true)} />
      );
    case 'UNAVAILABLE':
      return (
        <FaClock size={24} color={changeStatusColor('UNAVAILABLE', true)} />
      );
    case 'OPEN':
      return <FaLockOpen size={24} color={changeStatusColor('DONE', true)} />;
    case 'LOCKED':
      return (
        <FaLock size={24} color={changeStatusColor('DONE_REPROVED', true)} />
      );
    default:
      return <MdStars size={27} color={changeStatusColor(status, true)} />;
  }
}

export function renderUploadThumb(extension, file) {
  if (/pdf/i.test(extension)) {
    return <Img src={imagens.pdfImg} alt={file.name} />;
  }
  if (/plain|txt/i.test(extension)) {
    return <Img src={imagens.txtImg} alt={file.name} />;
  }
  if (/\bdoc\b|\bdocx\b|\bdocument\b/i.test(extension)) {
    return <Img src={imagens.docImg} alt={file.name} />;
  }
  if (/\bppt\b|\bpptx\b|\bpresentation\b/i.test(extension)) {
    return <Img src={imagens.pptImg} alt={file.name} />;
  }
  if (/zip|rar|7z/i.test(extension)) {
    return <Img src={imagens.zipImg} alt={file.name} />;
  }
  if (/\bxls\b|\bxlsx\b|\bsheet\b/i.test(extension)) {
    return <Img src={imagens.xlsImg} alt={file.name} />;
  }
  if (/png|jpg|jpeg|gif|webp|image/i.test(extension)) {
    return <Img src={imagens.jpgImg} alt={file.name} />;
  }
  return <Img src={imagens.notFound} alt={file.name} />;
}

export function setSEOIndexOff() {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex, nofollow';

  document.head.appendChild(meta);
}

export function changeFavIconNaReal() {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconNaReal;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function isIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // other browser
  return false;
}

export function formatDateYMD(data) {
  if (data) {
    const split = data.split('-');
    return `${split[2]}/${split[1]}/${split[0]}`;
  }
  return false;
}

export function formatDateDMY(date) {
  const data = new Date(date);

  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const year = data.getFullYear();
  const dataFormatada = [day, month, year].join('/');

  return dataFormatada;
}

export function maskNascimento(value) {
  if (value) {
    value = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Coloca uma barra entre o segundo e o terceiro dígitos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Coloca uma barra entre o quarta e o quinto dígitos
    return value;
  }
  return false;
}

export function isURL(str) {
  if (str) {
    const urlRegex =
      '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    const url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
  }
  return false;
}

export function ajusteCasasDecimais(nr, casas) {
  const og = Math.pow(10, casas);
  return Math.floor(nr * og) / og;
}

export function formatReal(int) {
  // int parseInt( str.replace(/[\D]+/g,'') );
  return int
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
}

export function verifyObject(obj) {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
  return false;
}

export function validateDate(value) {
  const regex = /^(0?[1-9]|1[012])[\/\-]\d{4}$/;

  return regex.test(value);
}

export function validateDate2(value) {
  const RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02]) {6}[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
  return RegExPattern.test(value);
}

export function validateDate3(value) {
  const regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  return regex.test(value);
}

export function isEmailValid(value) {
  if (value) {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@[a-z0-9-]+\.[a-z]+(\.[a-z]+)?$/;
    return regex.test(value);
  }
  return false;
}

export function isPhoneValid(value) {
  if (value) {
    const regex = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g;
    return regex.test(value);
  }
  return false;
}

export function formatPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/^(\+|0?\s*?55)/, '');
}

export function maskCpf(value) {
  if (value) {
    value = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    //                                          //de novo (para o segundo bloco de números)
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
  }
  return value;
}

export function maskDocument(value) {
  if (value) {
    value = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
    value = value.replace(/(\d{8})(\d)/, '$1 $2'); // Coloca um espaço entre o oitavo e o nono dígitos
  }
  return value;
}

export function maskDocs(value, documentType) {
  switch (documentType) {
    case 'IDAMERICALATINA':
      return value.toUpperCase();
    case 'IDPORTUGAL':
    case 'IDANGOLA':
      return maskDocument(value);
    default:
      return maskCpf(value);
  }
}

export function validarCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  let result = true;
  [9, 10].forEach(function(j) {
    let soma = 0;

    let r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function(e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}

export function validarNIF(nif) {
  nif = nif.replace(/\D/g, '');
  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(
      nif.substr(0, 2)
    )
  )
    return false;

  const total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  const modulo11 = total - parseInt(total / 11) * 11;
  const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;
  return parseInt(nif[8], 10) === parseInt(comparador, 10);
}

export function validarCC(cc) {
  if (cc.length >= 9 && cc.length <= 14) {
    return true;
  }

  return false;
}

export function validarDocAmericaLatina(cc) {
  if (cc.length >= 4 && cc.length <= 20) {
    return true;
  }
  return false;
}

export function validateDocs(value, documentType) {
  switch (documentType) {
    case 'IDPORTUGAL':
      return validarNIF(value);
    case 'IDANGOLA':
      return validarCC(value);
    case 'IDAMERICALATINA':
      return validarDocAmericaLatina(value);
    default:
      return validarCpf(value);
  }
}

export function maskPhone(value) {
  if (value) {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  }
}

export function ordenarCargosPorData(entrada1, entrada2) {
  const data1 = entrada1.dateEnd;
  const data2 = entrada2.dateEnd;

  if (!data1) {
    return -1;
  }
  if (!data2) {
    return 1;
  }

  const split1 = data1.split('/');
  const split2 = data2.split('/');

  const objData1 = new Date(split1[2], [1], split1[0], 0, 0, 0, 0);
  const objData2 = new Date(split2[2], [1], split1[0], 0, 0, 0, 0);

  return objData2 - objData1;
}

export function ordenarExAcademicaPorData(entrada1, entrada2) {
  const data1 = entrada1.dateEnd;
  const data2 = entrada2.dateEnd;

  if (!data1) {
    return -1;
  }
  if (!data2) {
    return 1;
  }

  const split1 = data1.split('/');
  const split2 = data2.split('/');

  const objData1 = new Date(split1[1], [0], 1, 0, 0, 0, 0);
  const objData2 = new Date(split2[1], [0], 1, 0, 0, 0, 0);

  return objData2 - objData1;
}

export function formatEmailSameLength(emailString) {
  const splitEmail = emailString.split('@');

  const domain = splitEmail[1];
  const name = splitEmail[0];
  // eslint-disable-next-line radix
  const size = parseInt(name.length / 2);

  return name
    .substring(0, size)
    .concat(name.length - size > 1 ? Array(name.length - size).join('*') : '*')
    .concat('@')
    .concat(domain);
}

export function getIconNotifications(origem) {
  switch (origem) {
    case 'cursoNovo':
      return 'fa fa-book';
    case 'socialPost':
      return 'fa fa-users';
    case 'socialComentario':
      return 'fa fa-comment';
    case 'palestra':
    case 'chat':
      return 'fa fa-user';
    default:
      return 'fa fa-newspaper-o';
  }
}

/**
 * JavaScript Client Detection
 * (C) viazenetti GmbH (Christian Ludwig)
 */

export async function deviceHash() {
  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();

  // This is the visitor identifier:
  const visitorId = result.visitorId;
  return visitorId;
  // const components = await Fingerprint2.getPromise();
  // const values = components.map(component => component.value);
  // const murmur = Fingerprint2.x64hash128(values.join(""), 31);
  // return murmur;
}

export async function getInfosPortal() {
  const unknown = '-';

  // browser
  const nVer = navigator.appVersion;
  const nAgt = navigator.userAgent;
  let browser = navigator.appName;
  let version = `${parseFloat(navigator.appVersion)}`;
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset;
  let verOffset;
  let ix;

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 4);
  }
  // Edge
  else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
    browser = 'Microsoft Edge';
    version = nAgt.substring(verOffset + 5);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() == browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

  majorVersion = parseInt(`${version}`, 10);
  if (isNaN(majorVersion)) {
    version = `${parseFloat(navigator.appVersion)}`;
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // mobile version
  const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

  // system
  let os = unknown;
  const clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ];
  for (const id in clientStrings) {
    const cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  let osVersion = '';

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS X':
      osVersion = /Mac OS X (\d[\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion[3] | 0}`;
      break;
  }

  const position = { x: 0, y: 0 };

  return {
    browserName: browser,
    browserVersion: `${majorVersion} ${version}`,
    os,
    osVersion,
    isMobile: mobile,
    location: JSON.stringify(position)
  };
}

export async function getLocation(options = {}) {
  if (navigator.geolocation) {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

    const { latitude, longitude } = position.coords;
    return { x: latitude, y: longitude };
  }
  return 'Geolocation is not supported by this browser.';
}

export async function identifyLogin(userId, email) {
  const infoPortal = await getInfosPortal();
  console.log(infoPortal);
  let body;
  let url;
  if (userId) {
    body = {
      idLogin: userId,
      loginBy: 'P',
      browserName: infoPortal.browserName,
      browserVersion: infoPortal.browserVersion,
      osName: infoPortal.os,
      osVersion: infoPortal.osVersion,
      localization: infoPortal.location
    };
    url = `/webbff/lit_app_web/profile/doLogUserSuccess`;
  } else {
    body = {
      email,
      wsReturn: 'Error ',
      browserName: infoPortal.browserName,
      browserVersion: infoPortal.browserVersion,
      osName: infoPortal.os
    };
    url = `/webbff/lit_app_web/profile/doLogUserFail`;
  }
  gateway.post(url, body);
}

export function generateSecurePassword(password = '') {
  return `${md5(password)}@hashed`;
}

// gateway
export async function logCreateUser(data, status) {
  const infoPortal = await getInfosPortal();

  const userName = data.login ? data.login.username : data.email;
  const email = data.login ? data.login.username : data.email;
  const firstName = data.firstName || data.name;

  const body = {
    webService: 'LIT - Portal',
    userInsert: 'ADM',
    isUser: 2,

    userName,
    email,
    firstName,
    lastName: data.lastName,
    dateCreated: Date.now(),

    plataform: 'Browser',
    browserName: infoPortal.browserName,
    browserVersion: infoPortal.browserVersion,
    osName: infoPortal.os,
    osVersion: infoPortal.osVersion,
    localization: infoPortal.location,
    urlCalled: status.urlCalled,

    message: status.message,
    status: status.status,
    dateRequest: status.dateRequest
  };

  const url = `/log/v1/user/createuseronlit/front`;

  gateway.post(url, body);
}

export function isDomainValid(email) {
  if (!email) return false;
  const domains = ['@bvnet', '@bv.com.br'];
  return domains.some(domain => email.includes(domain));
}

export function getLoginTenantURL(url = '') {
  let domain = '';
  const splittedURL = url.split('/');
  const lastItemIndexOfURL = _.lastIndexOf(splittedURL) - 1;
  if (splittedURL[lastItemIndexOfURL].includes('?')) {
    const [domainName] = splittedURL[lastItemIndexOfURL].split('?');
    domain = domainName;
  } else {
    domain = splittedURL[lastItemIndexOfURL];
  }

  if (tenantsValids.includes(domain.toUpperCase())) {
    return domain.toUpperCase();
  }
  return '';
}

export function formatRealDecimaisString(value) {
  // Instanciando o objeto
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  // pega o valor no campo e transforma em float
  const valor = parseFloat(value.replace(',', '.'));
  /* FORMATA CONFORME CONFIGURAÇÕES DEFINIDAS NO formatter */
  const formatado = formatter.format(valor);
  return formatado;
}

export async function renewToken() {
  try {
    return gateway.post('/gateway/token/renew');
  } catch (error) {
    console.log(`Não foi possível renovar o token. ${error.message}`);
  }
}

export async function showOldCourses() {
  try {
    const tenants = process.env.REACT_APP_SHOW_OLD_COURSES_TENANTS
      ? _.split(process.env.REACT_APP_SHOW_OLD_COURSES_TENANTS, ',')
      : false;

    if (!tenants) return false;

    const currentTenant = localStorage.getItem('tenant');

    return tenants.includes(currentTenant);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function getExtension(path) {
  const idx = (~-path.lastIndexOf('.') >>> 0) + 2;
  return path.substr(path.lastIndexOf('/') - idx > -3 ? -1 >>> 0 : idx);
}

export function validateZIPCode(zipCode) {
  const zipCodePattern = /^\d{5}-?\d{3}$/g;
  return zipCodePattern.test(zipCode);
}

export function validateNumber(number) {
  const numberPattern = /^-?\d+(\.\d+)?$/;
  return numberPattern.test(number);
}

export const toastDefaultOptions = {
  autoClose: 30000,
  style: {
    fontFamily: 'Lato, sans-serif',
    background: '#27aae1',
    color: '#FFF'
  }
};

export const toastErrorAndWarningOptions = {
  ...toastDefaultOptions,
  style: {
    ...toastDefaultOptions.style,
    background: 'rgb(252,238,30)',
    color: '#000000'
  },
  theme: 'colored'
};

export const abbreviateText = (text, limit = 0) => {
  let txt = text;
  if (text.length > limit) {
    txt = `${text.substring(0, limit)} ...`;
  }
  return txt;
};

export const getInitialsTeacher = teacher => {
  const teacherName = _.get(teacher, 'name', '');
  const splitedName = teacherName.split(' ');
  const firstName = _.first(splitedName);
  const lastName = _.nth(splitedName, 1);
  let initials;

  if (firstName) initials = firstName.substring(0, 1);
  if (lastName) initials += lastName.substring(0, 1);

  return initials;
};

export const getStatusServer = async () => {
  try {
    const response = await gateway.get('/ping');
    return true;
  } catch (error) {
    history.push('/manutencao');
    return false;
  }
};
