import React, { useEffect, useState, Fragment } from 'react';
import _, { lastIndexOf } from 'lodash';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from 'styled-components';
import { gateway } from '../../../utils/api';
import LitOnlearning from '../../../images/lit.png';
import LitPassLogo from '../../../images/litpass-logo.png';
import TelaDeCadastro from '../../../images/volta_as_aulas_2025.png';
import CadastroLitPass from '../../../images/Cadastro_LITpass.png';
import ImgVocacao from '../../../images/logo-vocacao.png';

const TextP = styled.p`
  font-size: 1.3em;
  letter-spacing: 1px;
  color: #fff;

  &:first-child {
    font-weight: 600;
  }
`;

const BoxPromo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  background-size: ${props => (props.noContain ? 'none' : 'contain')};
  background-position: ${props => (props.noContain ? 'center' : 'bottom')};
`;

const TextSpotlight = styled.h2`
  font-size: 3.5rem;
  font-weight: lighter;
  color: #fff;
`;

const itens = [
  {
    subtitle: 'Inteligência Artificial para um melhor aprendizado',
    description:
      'O sistema aprende sobre você e sua aprendizagem é otimizada e personalizada.'
  },
  {
    subtitle: 'Aprendizagem Personalizada',
    description:
      'Avalie o seu conhecimento sobre o tema e elimine conteúdos do curso que você já conhece.'
  },
  {
    subtitle: 'Certificações',
    description:
      'Ao final dos cursos, você recebe certificações válidas e reconhecidas no mercado.'
  },
  {
    subtitle: 'Qualidade Saint Paul',
    description:
      'Todos os cursos possuem curadoria da Saint Paul Escola de Negócios.'
  }
];

const LitPassItens = [
  {
    subtitle: 'Conheça seu novo benefício de educação',
    description:
      'Dê o próximo passo em sua carreira com os cursos e trilhas do LITpass.'
  },
  {
    subtitle: 'Inteligência Artificial para um melhor aprendizado',
    description:
      'O sistema aprende sobre você e sua aprendizagem é otimizada e personalizada.'
  },
  {
    subtitle: 'Aprendizagem Personalizada',
    description:
      'Avalie o seu conhecimento sobre o tema e elimine conteúdos do curso que você já conhece.'
  },
  {
    subtitle: 'Certificações',
    description:
      'Ao final dos cursos, você recebe certificações válidas e reconhecidas no mercado.'
  },
  {
    subtitle: 'Qualidade Saint Paul',
    description:
      'Todos os cursos possuem curadoria da Saint Paul Escola de Negócios.'
  }
];

export default function LeftSideBar({ litpass, isVocacao, promo }) {
  const [signupSettingsState, setSignupSettingsState] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [bgDefaultHeight, setBgDefaultHeight] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('');
  const origin = localStorage.getItem('urlOriginParam');
  const loginTenant = localStorage.getItem('loginTenant');
  const [loadingImage, setLoadingImage] = useState(true);
  const [tenantsValids] = useState([
    'LIT',
    'ANGLO',
    'LIT_SCHOOL',
    'LIT_HOMOLOG',
    'LIT_SAMPLES',
    'HINODE',
    'IBEF',
    'SAINTPAUL',
    'LIT_BUSINESS',
    'OBJETIVO_ABC',
    'MICHAEL_PAGE',
    'EXEMPLO_2',
    'VOCACAO',
    'LIT_NEGOCIOS',
    'FARIAS_BRITO',
    'VERACEL',
    'BANCO_BRASILIA',
    'BIOLAB',
    'BOM_JESUS',
    'CERRADINHO',
    'FAIRFAX',
    'PATRIA',
    'TTG',
    'CAIXA_ASSET',
    'B2C_EXTENSAO',
    'INTERPLAYERS',
    'ARTESANAL_INVESTIMENTOS',
    'RNP',
    'ITAU_UNIBANCO',
    'ACADEMIA_INFOTEC'
  ]);

  const renderBackground = async () => {
    const origin = localStorage.getItem('urlOriginParam');
    const loginTenant = localStorage.getItem('loginTenant');
    const currentOrigin = origin || loginTenant;
    let response = null;
    try {
      response = await gateway.get(
        `webbff/lit_app_web/content/${currentOrigin}`
      );
      if (response) {
        signupSettingsState.backgroundImage && !origin
          ? setBackgroundImage(signupSettingsState.backgroundImage)
          : setBackgroundImage(_.get(response, 'url', ''));

        signupSettingsState.primaryBackgroundColor && !origin
          ? setBackgroundColor(signupSettingsState.primaryBackgroundColor)
          : setBackgroundColor(_.get(response, 'backgroundColor', ''));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingImage(false);
    }

    if (!response && promo) {
      setBackgroundImage(TelaDeCadastro);
    }

    if (litpass) {
      return CadastroLitPass;
    }

    if (isVocacao) {
      return ImgVocacao;
    }
  };

  const slideTo = i => {
    setCurrentIndex(i);
  };

  const thumbItem = (item, i) => {
    return (
      <div
        style={{
          width: 50,
          height: 5,
          borderRadius: 40,
          backgroundColor: currentIndex === i ? '#FFFFFF' : '#5FD9EE',
          cursor: 'pointer',
          marginRight: 10,
          display: 'inline-block'
        }}
        role="button"
        key={i}
        onClick={() => {
          slideTo(i);
        }}
      />
    );
  };

  const renderItems = () => {
    if (signupSettingsState.signupCarouselItems && !litpass) {
      return signupSettingsState.signupCarouselItems.map(item => {
        return (
          <div
            key={item.title}
            style={{ maxWidth: 500, marginLeft: '20%' }}
            className="card-body"
          >
            <TextSpotlight>{item.title}</TextSpotlight>
            <TextP>{item.subtitle}</TextP>
          </div>
        );
      });
    }

    if (litpass && !signupSettingsState.signupCarouselItems) {
      return LitPassItens.map(item => {
        return (
          <div
            key={item.subtitle}
            style={{ maxWidth: 500, marginLeft: '20%' }}
            className="card-body"
          >
            <TextSpotlight>{item.subtitle}</TextSpotlight>
            <TextP>{item.description}</TextP>
          </div>
        );
      });
    }

    if (!signupSettingsState.signupCarouselItems && !litpass) {
      return itens.map(item => {
        return (
          <div
            key={item.subtitle}
            style={{ maxWidth: 500, marginLeft: '20%' }}
            className="card-body"
          >
            <TextSpotlight>{item.subtitle}</TextSpotlight>
            <TextP>{item.description}</TextP>
          </div>
        );
      });
    }
  };

  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const checkSizeChanged = () => {
    const currentHeight = getHeight();
    setBgDefaultHeight(currentHeight);
  };

  useEffect(() => {
    const getSignupSettings = async () => {
      let domain = '';
      let isValidTenant = false;
      const splittedURL = window.location.href.split('/');
      const lastItemIndexOfURL = lastIndexOf(splittedURL) - 1;
      if (splittedURL[lastItemIndexOfURL].includes('?')) {
        const [domainName] = splittedURL[lastItemIndexOfURL].split('?');
        domain = domainName;
      } else {
        domain = splittedURL[lastItemIndexOfURL];
      }

      if (tenantsValids.includes(domain.toUpperCase())) {
        isValidTenant = true;
      }

      if (isValidTenant) {
        try {
          localStorage.setItem('loginTenant', domain.toUpperCase());
          const domainSettings = await gateway.get(
            `/webbff/lit_app_web/settings/domainSetting/${domain}`
          );
          localStorage.setItem(
            'domainSettings',
            JSON.stringify(domainSettings)
          );
          setSignupSettingsState(domainSettings);
        } catch (err) {
          console.log('err', err.message);
        }
      } else {
        localStorage.setItem('loginTenant', '');
        const domainSettingsUnformatted = localStorage.getItem(
          'domainSettings'
        );
        const domainSettingsFormatted = JSON.parse(domainSettingsUnformatted);
        if (domainSettingsFormatted) {
          setSignupSettingsState(domainSettingsFormatted);
        }
      }
    };

    getSignupSettings();
  }, []);

  useEffect(() => {
    setItems(renderItems());
    renderBackground();
  }, [signupSettingsState]);

  useEffect(() => {
    checkSizeChanged();
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        checkSizeChanged();
      }, 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <>
      {(origin || loginTenant || promo) && !isVocacao && (
        <>
          {loadingImage && (
            <Fragment>
              <div
                className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big"
                style={{
                  backgroundColor,
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <BoxPromo noContain={isVocacao} />
              </div>
            </Fragment>
          )}
          {backgroundImage && !loadingImage && (
            <Fragment>
              <div
                className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big"
                style={{
                  backgroundColor,
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: bgDefaultHeight,
                  maxWidth: '100%'
                }}
              >
                <BoxPromo noContain={isVocacao} />
              </div>
            </Fragment>
          )}
          {!backgroundImage && !loadingImage && (
            <Fragment>
              <div
                className={`col-md-7 flex-column justify-content-start d-none d-md-flex ${!signupSettingsState.primaryBackgroundColor &&
                  'bg-loginLeft'}  big`}
                style={
                  signupSettingsState.domain && {
                    backgroundImage: `url(${signupSettingsState.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundColor: signupSettingsState.primaryBackgroundColor
                  }
                }
              >
                {litpass ? (
                  <img
                    src={LitPassLogo}
                    width={200}
                    className="align-self-start"
                    alt="lit-logo"
                  />
                ) : null}

                {!litpass && signupSettingsState.domain !== 'hinode' ? (
                  <img
                    src={signupSettingsState.logoUrl || LitOnlearning}
                    height={100}
                    className="align-self-start ml-4 my-5"
                    alt="lit-logo"
                  />
                ) : null}

                <div
                  className={`w-100 flex-column align-items-center ${
                    signupSettingsState.domain === 'hinode'
                      ? 'd-none'
                      : 'd-flex'
                  }`}
                >
                  <TextP>
                    {signupSettingsState.signupCarouselTitle ||
                      'Comece agora a sua jornada de aprendizagem'}
                  </TextP>
                  <AliceCarousel
                    items={items}
                    startIndex={0}
                    mouseDragEnabled
                    dotsDisabled
                    slideToIndex={currentIndex}
                    buttonsDisabled
                  />
                  <nav>
                    {litpass && LitPassItens.map(thumbItem)}
                    {!litpass && itens.map(thumbItem)}
                  </nav>
                </div>
              </div>
              <div className="col-md d-md-none bg-loginLeft small">
                {/* <img src={background} className="logo" alt="LIT" /> */}
              </div>
            </Fragment>
          )}
        </>
      )}
      {!promo && !origin && !loginTenant && !isVocacao && (
        <Fragment>
          <div
            className={`col-md-7 flex-column justify-content-start d-none d-md-flex ${!signupSettingsState.primaryBackgroundColor &&
              'bg-loginLeft'}  big`}
            style={
              signupSettingsState.domain && {
                backgroundImage: `url(${signupSettingsState.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundColor: signupSettingsState.primaryBackgroundColor
              }
            }
          >
            {litpass ? (
              <img
                src={LitPassLogo}
                width={200}
                className="align-self-start"
                alt="lit-logo"
              />
            ) : null}

            {!litpass && signupSettingsState.domain !== 'hinode' ? (
              <img
                src={signupSettingsState.logoUrl || LitOnlearning}
                height={100}
                className="align-self-start ml-4 my-5"
                alt="lit-logo"
              />
            ) : null}

            <div
              className={`w-100 flex-column align-items-center ${
                signupSettingsState.domain === 'hinode' ? 'd-none' : 'd-flex'
              }`}
            >
              <TextP>
                {signupSettingsState.signupCarouselTitle ||
                  'Comece agora a sua jornada de aprendizagem'}
              </TextP>
              <AliceCarousel
                items={items}
                startIndex={0}
                mouseDragEnabled
                dotsDisabled
                slideToIndex={currentIndex}
                buttonsDisabled
              />
              <nav>
                {litpass && LitPassItens.map(thumbItem)}
                {!litpass && itens.map(thumbItem)}
              </nav>
            </div>
          </div>
          <div className="col-md d-md-none bg-loginLeft small">
            {/* <img src={background} className="logo" alt="LIT" /> */}
          </div>
        </Fragment>
      )}

      {isVocacao && (
        <Fragment>
          <div className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big">
            <BoxPromo
              noContain={isVocacao}
              style={{
                backgroundImage: `url(${renderBackground()})`
              }}
            />
          </div>
        </Fragment>
      )}
    </>
  );
}
