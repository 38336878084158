import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const NavBar = () => {
  const [settings, setSettings] = useState(null);
  const [tenant, setTenant] = useState('');
  const savedSettings = JSON.parse(localStorage.getItem('settings')) || {};

  useEffect(() => {
    const savedTenant = localStorage.getItem('tenant') || '';

    if (!settings) {
      setSettings(savedSettings);
      setTenant(savedTenant);
    }
  }, [savedSettings]);

  if (!settings) {
    return <div>Loading...</div>;
  }

  const logoByTenant = tenant ? _.get(settings[tenant], 'logoUrl', '') : '';
  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
    settings,
    `${tenant}`,
    {}
  );

  return (
    <div
      style={{
        background: headerBackgroundColor || headerBackgroundGradient,
        textAlign: 'center',
        padding: '8px',
      }}
    >
      <a
        className="navbar-brand"
        style={{
          backgroundColor: headerBackgroundColor,
          minWidth: 150,
          padding: '0rem 1rem',
        }}
      >
        <img
          src={logoByTenant}
          alt="Logo LIT"
          style={{
            maxWidth: '100%',  // Responsivo: se a tela for pequena, a imagem vai se ajustar
            height: 'auto',    // Mantém a proporção da imagem
            maxHeight: '45px', // Limita a altura máxima do logo
            objectFit: 'contain', // Garante que a imagem não seja distorcida
          }}
        />
      </a>
    </div>
  );
};

export default NavBar;
