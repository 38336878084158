import React, { useState, useMemo } from 'react';
import swal from '@sweetalert/with-react';
import _ from 'lodash';

import { gateway } from '../../../../utils/api';
import createUserTerm from '../../helpers/createUserTerm';
import extractTermTemplateReference from '../../helpers/extractTermTemplateReference';
import handleRequestErrorMessage from '../../helpers/handleRequestErrorMessage';
import handleFormSubmitHelper from '../../helpers/handleFormSubmit';
import Form from './Form';
import { createVindiCustomer } from '../../../../utils/vindi';

function InvoiceForm({ selectedPlan, onPaymentSuccess, couponCode }) {
  const [loading, setLoading] = useState(false);
  const [generatingTerm, setGeneratingTerm] = useState(false);
  const [alreadyGeneratedTerm, setAlreadyGeneratedTerm] = useState(false);
  const [userTerm, setUserTerm] = useState(null);
  const [showTerm, setShowTerm] = useState(false);

  const termTemplateReference = useMemo(
    () =>
      extractTermTemplateReference({
        selectedPlan,
        paymentMethod: 'INVOICE'
      }),

    [selectedPlan]
  );

  async function handleSubmitPayment(formValues) {
    try {
      setLoading(true);
      const createCustomerData = {
        name: `${formValues.wl_shopperfirstname.trim()} ${formValues.wl_shopperlastname.trim()}`,
        address: {
          street: formValues.wx_shopperstreet,
          number: formValues.ex_shopperhousenumber,
          additional_details: formValues.additional_details,
          zipcode: formValues.wx_shopperpostalcode,
          neighborhood: formValues.neighborhood,
          city: formValues.wx_shoppercity,
          state: formValues.wx_shopperstate,
          country: formValues.wx_shoppercountry
        },
        cpf: formValues.cf_shoopercpf,
        paymentMethod: 'INVOICE',
      }

      await createVindiCustomer(createCustomerData).catch(() => {
        throw new Error('Não foi possível processar o pagamento. Por favor, verifique os dados preenchidos!');
      });

      const { installments, ...ox_invoicepaymentdata } = formValues;
      const paymentRequestBody = {
        crCodeCoupon: couponCode,
        type: 'INVOICE',
        ox_invoicepaymentdata: {
          ...ox_invoicepaymentdata,
          cf_shoopercpf: ox_invoicepaymentdata.cf_shoopercpf.replace(/\D/g, ''),
          wx_shopperpostalcode: ox_invoicepaymentdata.wx_shopperpostalcode.replace(
            /\D/g,
            ''
          )
        },
        offerReference: selectedPlan.code,
        installments,
        term: userTerm
          ? {
            mediaCode: _.get(userTerm, 'ox_standard.ox_identification.cr_code'),
            templateCode: termTemplateReference
          }
          : null,
        sellerCode: localStorage.getItem('sellerCode'),
        sellerLinkId: localStorage.getItem('sellerLinkId'),
      };

      localStorage.removeItem('pixData');

      const url = selectedPlan.alreadyPurchased
        ? '/contract/contract/updatePaymentMethodVindi'
        : '/contract/contract/processSubscription';
      const method = selectedPlan.alreadyPurchased ? 'put' : 'post';

      const paymentResponse = await gateway[method](url, paymentRequestBody);

      const transactionCode = _.get(
        paymentResponse,
        'transactions.0.ox_standard.ox_identification.cr_code',
        null
      );

      onPaymentSuccess({
        selectedPlan,
        paymentResult: { ...paymentResponse.paymentInfo, transactionCode },
        paymentMethod: 'INVOICE',
        contractCode: _.get(
          paymentResponse,
          'contract.ox_standard.ox_identification.cr_code',
          ''
        )
      });
    } catch (error) {
      const message = _.get(
        error,
        'response.data.message',
        'Não foi possível processar o pagamento.'
      );
      swal({
        title: `Ops, ocorreu um erro.`,
        text: message
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleGenerateTerm(values) {
    try {
      if (alreadyGeneratedTerm) {
        setShowTerm(true);
        return;
      }

      setGeneratingTerm(true);
      const response = await gateway.post(
        '/webbff/lit_app_web/term/generatePaymentTerm',
        {
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        }
      )

      if (!response) {
        setUserTerm(null);
        setShowTerm(false);
        setAlreadyGeneratedTerm(false);
        setGeneratingTerm(false);
        handleSubmitPayment(values);
        return;
      }

      setUserTerm(response);
      setShowTerm(true);
      setAlreadyGeneratedTerm(true);
    } catch (error) {
      console.log(error);
      setUserTerm(null);
      setShowTerm(false);
      setAlreadyGeneratedTerm(false);
      setGeneratingTerm(false);
      handleSubmitPayment(values);
    } finally {
      setGeneratingTerm(false);
    }
  }

  async function handleOpenTerm() {
    try {
      setGeneratingTerm(true);
      if (!userTerm) {
        const response = await createUserTerm({
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        });
        setUserTerm(response);
      }
    } catch (error) {
      handleRequestErrorMessage(error);
    } finally {
      setGeneratingTerm(false);
    }
  }

  return (
    <Form
      handleGenerateTerm={handleGenerateTerm}
      onSubmit={formValues =>
        handleFormSubmitHelper({
          selectedPlan,
          processPaymentFunction: handleSubmitPayment,
          formValues
        })
      }
      termTemplateReference={termTemplateReference}
      selectedPlan={selectedPlan}
      userTerm={userTerm}
      onOpenTerm={handleOpenTerm}
      generatingTerm={generatingTerm}
      showTerm={showTerm}
      setShowTerm={setShowTerm}
      loading={loading}
    />
  );
}

export default InvoiceForm;
